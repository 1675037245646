/*!

=========================================================
* Material Dashboard PRO React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";

import "assets/scss/material-dashboard-pro-react.scss?v=1.9.0";

import { Auth } from 'aws-amplify';
import awsconfig from './aws-exports'

import { Provider } from "react-redux";
import generateStore from "./redux/store";

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { primaryColor } from 'assets/jss/material-dashboard-pro-react.js'

import App from "./App";

import {strings as translate} from "./locale";

if(window.location.href.match(/\/src\/#\//gi)) { 
  // si intenta ingresar al ET viejo hay que redireccionarlo a una url sin el hash #
  // para que Route pueda capturarlo
  setTimeout(()=>{
    window.location.href = window.location.href.replace("#", "et")
  }, 2000);
  
  ReactDOM.render(
    <div 
      style={{
        display:"flex", 
        width: "100vw", 
        height: "100vh", 
        flex: 1, 
        justifyContent: "center", 
        alignItems: "center"
      }}
    >
      {translate.Redirecting}...
    </div>,
    document.getElementById("root")
  );
} else {
  Auth.configure(awsconfig);
  
  const store = generateStore();
  
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: primaryColor[0],
      },
      secondary: {
        main: primaryColor[1],
      },
    },
  });
  
  ReactDOM.render(
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Provider>,
    document.getElementById("root")
  );
}

