import React, { useEffect, useState } from 'react'
import moment from 'moment'
import 'moment/locale/es';
import { trackPromise } from 'react-promise-tracker';
import LoadingIndicator from 'components/Loader/LoadingIndicator';

// nodejs library that concatenates classes
import classNames from "classnames";

import AuthServices from '../../services/AuthServices'
import CoachServices from '../../services/CoachServices'

import { withStyles } from '@material-ui/core/styles';

import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Button from 'components/CustomButtons/Button'
import { Card, Fade, makeStyles, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core'

import controlPanelStyle from 'assets/jss/material-dashboard-pro-react/views/controlPanelStyle'

const useStyles = makeStyles(controlPanelStyle);
moment.locale('es')

const CoachPanel = () => {

    const authUser = AuthServices.getUser();
    const user = authUser.user;
    const [isLoading, setIsLoading] = useState(true)
    const [upcomingActivities, setUpcomingActivities] = useState({plans:[], competitions: [], controls: []})
    const classes = useStyles();

    useEffect(() => {
        const getEvents = async () => {
            try {
                const res = await CoachServices.getUpcomingActivities();
                if(res.data.response) {
                    const data = res.data.response;
                    setUpcomingActivities(data)
                }
            } catch (error) {
                console.log(error);
            }
        }
        trackPromise(getEvents(), 'coach').then(()=>{
            setIsLoading(false)
        });
    }, [])

    return (
    <>
        <LoadingIndicator
            type="TailSpin"
            color="#2e82ef"
            area="coach"
            height={50}
            width={50}
        />
        { !isLoading &&
        <Fade in>
            <GridContainer>
                {/* START PLANES Y VENCIMIENTOS */}
                <GridItem
                    container
                    direction="column"
                    justify="center"
                    alignContent="flex-start"
                    alignItems="center"
                    flex="1"
                    xs={12}
                    className={classes.tableContainer}
                >
                    <h4 className={classes.tableTitle}>Planes y vencimientos</h4>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Atleta</TableCell>
                                <TableCell>Vencimiento</TableCell>
                                <TableCell>Plan</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { upcomingActivities.plans.length === 0 &&
                            <TableRow>
                                <TableCell colSpan="3">
                                    <Typography variant="caption">
                                        No existen vencimientos en los próximos 7 días
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            }
                            { upcomingActivities.plans.length > 0 &&
                            upcomingActivities.plans.map((item, key) => (<TableRow key={key}>
                                <TableCell>
                                    {item.athlete_name}
                                </TableCell>
                                <TableCell>
                                    {moment(item.last_session_date).format('dd/MM/yyyy')}
                                </TableCell>
                                <TableCell>
                                    {item.title}
                                </TableCell>
                            </TableRow>))
                            }
                        </TableBody>
                    </Table>

                    <Button
                        color="primary"
                        size="sm"
                    >
                        ATLETA
                    </Button>
                </GridItem>
                {/* END PLANES Y VENCIMIENTOS */}

                {/* START COMPENTENCIAS */}
                <GridItem
                    container
                    direction="column"
                    justify="center"
                    alignContent="flex-start"
                    alignItems="center"
                    flex="1"
                    xs={12}
                    className={classes.tableContainer}
                >
                    <h4 className={classes.tableTitle}>Competencias</h4>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Atleta</TableCell>
                                <TableCell>Vencimiento</TableCell>
                                <TableCell>Plan</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { upcomingActivities.competitions.length === 0 &&
                            <TableRow>
                                <TableCell colSpan="3">
                                    <Typography variant="caption">
                                        No existen vencimientos en los próximos 7 días
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            }
                            { upcomingActivities.competitions.length > 0 &&
                            upcomingActivities.competitions.map((item, key) => (
                            <TableRow key={key}>
                                <TableCell>
                                    {item.athlete_name}
                                </TableCell>
                                <TableCell>
                                    {moment(item.last_session_date).format('dd/MM/yyyy')}
                                </TableCell>
                                <TableCell>
                                    {item.title}
                                </TableCell>
                            </TableRow>
                            ))
                            }
                        </TableBody>
                    </Table>

                    <Button
                        color="primary"
                        size="sm"
                    >
                        COMPETENCIAS
                    </Button>
                </GridItem>
                {/* END COMPENTENCIAS */}

                {/* START TEST Y CONTROLES */}
                <GridItem
                    container
                    direction="column"
                    justify="center"
                    alignContent="flex-start"
                    alignItems="center"
                    flex="1"
                    xs={12}
                    className={classes.tableContainer}
                >
                    <h4 className={classes.tableTitle}>Test y controles</h4>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Atleta</TableCell>
                                <TableCell>Vencimiento</TableCell>
                                <TableCell>Plan</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        { upcomingActivities.controls.length === 0 &&
                            <TableRow>
                                <TableCell colSpan="3">
                                    <Typography variant="caption">
                                        No existen vencimientos en los próximos 7 días
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            }
                            { upcomingActivities.controls.length > 0 &&
                            upcomingActivities.controls.map((item, key) => (
                            <TableRow key={key}>
                                <TableCell>
                                    {item.athlete_name}
                                </TableCell>
                                <TableCell>
                                    {moment(item.last_session_date).format('dd/MM/yyyy')}
                                </TableCell>
                                <TableCell>
                                    {item.title}
                                </TableCell>
                            </TableRow>
                            ))
                            }
                        </TableBody>
                    </Table>

                    <Button
                        color="primary"
                        size="sm"
                    >
                        TEST Y CONTROLES
                    </Button>
                </GridItem>
                {/* END TEST Y CONTROLES */}
            </GridContainer>
        </Fade>
        }
    </>
    )
}

export default CoachPanel
