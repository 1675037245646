import {
    container,
    cardTitle,
    primaryColor,
    dangerColor,
    blackColor,
    hexToRgb,
    grayColor
  } from "assets/jss/material-dashboard-pro-react.js";
  
  import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
  import customInputStyle from "assets/jss/material-dashboard-pro-react/components/customInputStyle.js";
  
  const signupPageStyle = theme => ({
    ...customInputStyle,
    ...customCheckboxRadioSwitch,
    signUpContainer: {
      width: "100%",
      padding: "0 15px",
      zIndex: "4",
    },
    mainContainer: {
      display: "flex",
      flexDirection: "row"
    },
    header: {
      flex: "1 1 100%",
      minHeight: "116px",
      maxHeight: "116px",
      backgroundColor: grayColor[16],
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    content: {
      flex: "1 1 100%"
    },
    contentContainerFinished: {
      backgroundColor: "white",
      minHeight: "80vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: "12px"
    },
    h2: {
      color: "#F66129",
      textAlign: "center",
      fontSize: "30px",
      fontWeight: "bold",
      marginTop: "20px",
      marginBottom: "10px",
    },
    text: {
      color: "#F66129",
      textAlign: "center",
      fontSize: "1rem",
      fontWeight: "400",
      letterSpacing: "0.010em"
    },
    iconCheck: {
      backgroundColor: "#F66129",
      padding: "16px 16px",
      borderRadius: "50%",
      margin: "15px 0 45px 0",
      fontSize: "60px",
      width: "90px",
      height: "90px",
      "& .material-icons": {
        fontSize: "60px",
        fontWeight: "bold"
      }
    },
    buttonLogin: {
      backgroundImage: "none",
      backgroundColor: "#F15A24",
      textShadow: "none",
      boxShadow: "none",
      border: "none",
      padding: "5px 15px !important",
      transition: ".2s ease-in-out",
      color: "#fff",
      display: "inline-block",
      padding: "6px 12px",
      marginBottom: "0",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "1.42857143",
      textAlign: "center",
      whiteSpace: "nowrap",
      verticalAlign: "middle",
      "-ms-touch-action": "manipulation",
      "touch-action": "manipulation",
      cursor: "pointer",
      "-webkit-user-select": "none",
      "-moz-user-select": "none",
      "-ms-user-select": "none",
      "user-select": "none",
      backgroundImage: "none",
      border: "1px solid transparent",
      borderRadius: "4px",
      textAlign: "center",
      "&:hover": {
        "background-color": "#b24f36",
        transition: ".2s ease-in-out"
      },
      "&:focus": {
        "outline": "none !important",
        "box-shadow": "none !important",
        "text-decoration": "none !important",
      }
    },
    footer: {
      flex: "1 1 100%",
      backgroundColor: primaryColor[0]
    },
    logo: {
      display: "inline-block",
      width: "auto",
      maxHeight: "100px !important",
      height: "initial !important",
    },
    footerTop: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "stretch",
      maxWidth: "1170px",
      margin: "15px auto 0 auto",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        justifyContent: "flex-start",
      }
    },
    logoMenuFooter: {
      display: "flex",
      flex: "1 1 auto"
    },
    logoFooter: {
      position: "relative",
      "&:after": {
        content: '""',
        display: "block",
        position: "absolute",
        height: "100%",
        width: "1px",
        borderRight: "1px solid #FFFFFF",
        right: "0px",
        top: "0",
      },
      [theme.breakpoints.down("sm")]: {
        flex: "1 1 50%",
        maxWidth: "50%"
      }
    },
    menuFooter: {
      marginLeft: '15px',
      [theme.breakpoints.down("sm")]: {
        flex: "1 1 50%",
        maxWidth: "50%"
      }
    },
    logoImage: {
      height: "65px !important",
      [theme.breakpoints.down("sm")]: {
        height: "auto !important",
        width: "100%"
      }
    },
    menuLink: {
      color: "white",
      display: "block",
      fontSize: "12px",
      "&:hover": {
        color: grayColor[16]
      }
    },
    socialMedia: {
      display: "flex",
      flex: "1 1 auto",
      justifyContent: "flex-end",
      alignItems: "flex-end",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
        marginTop: "35px"
      }
    },
    socialIcon: {
      "& svg" : {
        fill: "rgba(255,255,255,.5)"
      },
      "&:not(:first-child)": {
        marginLeft: "5px"
      }
    },
    footerBottom: {
      maxWidth: "1170px",
      margin: "15px auto 15px auto"
    },
    copyright: {
      textAlign: "center",
      fontSize: "12px",
      padding: "0 12%",
      lineHeight: "1.2",
      [theme.breakpoints.down("sm")]: {
        marginTop: "32px"
      }
    },
    content: {
      flex: "1 1 100%",
      padding: "0 !important"
    },
    contentContainer: {
      display: "flex"
    },
    contentLeft: {
      flex: "1 1 33.333333%",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center top",
      position: "relative",
      "&:after": {
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        display: "block",
        height: "100%",
        width: "100%",
        backgroundColor: "white",
        opacity: 1,
        transition: "all .5s"
      },
      [theme.breakpoints.down("sm")]: {
        display: "none"
      }
    },
    contentForm: {
      flex: "1 1 33.333333%",
      backgroundColor: "#FFF",
      padding: "0 40px",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",
        flex: "1 1 100%",
      }
    },
    contentRight: {
      flex: "1 1 33.333333%",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center top",
      position: "relative",
      "&:after": {
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        display: "block",
        height: "100%",
        width: "100%",
        backgroundColor: "white",
        opacity: 1,
        transition: "all .5s"
      },
      [theme.breakpoints.down("sm")]: {
        display: "none"
      }
    },
    bgActive: {
      "&:after": {
        opacity: 0
      }
    },
    signupFooter: {
      textAlign: "center",
      margin: "60px 0"
    },
    inputFormControl: {
      marginBottom: "10px !important"
    },
    radioFormControl: {
      width: "100%",
      textAlign: "center",
      paddingTop: 0,
      marginTop: "27px",
      marginBottom: 0
    },
    radioGroup: {
      display: "flex",
      justifyContent: "center"
    },
    radioLegend: {
      marginBottom: 0,
      color: "rgba(0, 0, 0, 0.54) !important"
    },
    radioChecked: {
      "& svg": {
        fill: primaryColor[0]
      }
    },
    labelRoot: {
      margin: '0 8px'
    },
    inputAdornmentIconButton: {
      fill: primaryColor[0]
    },
    signupFooter: {
      textAlign: "center",
      padding: "60px 0",
      fontSize: ".75rem",
      fontWeight: "400",
      lineHeight: "0.875rem",
      "& p": {
        color: primaryColor[0],
        marginBottom: 0,
        fontSize: "inherit",
        fontWeight: "inherit",
        lineHeight: "inherit"
      },
      "& button": {
        color: primaryColor[0],
        padding: 0,
        margin: 0,
        fontSize: "inherit",
        fontWeight: "inherit",
        lineHeight: "inherit",
        "&:hover, &:active, &:visited, &:focus": {
          color: grayColor[16]
        }
      }
    },
    formTextError: {
      color: dangerColor[0]
    },
    buttonWithLoader: {
      marginTop: "27px",
      position: "relative",
      "& > div": {
        position: "absolute",
        height: "100%",
        width: "100%",
        zIndex: "2",
        "& > div": {
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }
      },
      "& > button": {
        zIndex: 1
      }
    }
  });
  
  export default signupPageStyle;
  