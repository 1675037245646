import { primaryColor, successColor } from "assets/jss/material-dashboard-pro-react.js";

const checkoutStyle = {
    title: {
        color: primaryColor[0],
        fontSize: '18px',
        textAlign: 'center',
        lineHeight: '1.1',
        fontWeight: '500',
        marginBottom: '10px',
        marginTop: '20px',
    },
    subtitle: {
        color: primaryColor[0],
        fontSize: '14px',
        textAlign: 'center',
        lineHeight: '1.1',
        fontWeight: '500',
        marginTop: '0'
    },
    form: {
        marginBottom: '30px',
        marginTop: '30px'
    },
    paymentOption: {
        margin: '7px 0',
        "& > div": {
            paddingBottom: 0,
            paddingTop: 0,
        }
    },
    paymentOptionDisabled: {
        color: '#999999 !important',
        "& img": {
            filter: 'saturate(0.1)',
            opacity: '0.3'
        }
    },
    logoContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        fontSize: '.75rem'
    },
    paymentLogo: {
        height: 'auto',
        marginLeft: '10px',
        width: '100px'
    },
    footer: {
        borderTop: '1px solid #CCC',
        marginTop: '15px',
        paddingTop: '30px'
    },
    footerText: {
        marginBottom: '15px',
        marginTop: '15px',
    },
    safeText: {
        fontSize: '12px'
    },
    buttonPayment: {
        fontSize: '18px',
        fontWeight: '400',
        "& strong": {
            fontWeight: '700',
            display: 'inline-block',
            marginLeft: '7px'
        }
    },
    buttonCancel: {
        fontSize: '12px',
        textTransform: 'none'
    },
    footerButtons: {
        marginBottom: '15px',
        marginTop: '15px'
    },
    lockIcon: {
        color: successColor[4],
        verticalAlign: 'middle'
    },
    iegLogo: {
        height: 'auto',
        width: '100%'
    },
    itemContainer: {
        marginTop: '15px'
    },
    itemName: {
        fontSize: '16px',
        fontWeight: '700',
        margin: '0',
        color: '#000000'
    },
    itemDescription: {
        fontSize: '14px',
        fontWeight: '400',
        margin: '0',
        color: '#000000'
    },
    itemPrice: {
        fontSize: '26px',
        fontWeight: '400',
        textAlign: 'right',
        margin: '0',
        color: '#666666'
    },
    itemTotalTitle: {
        fontSize: '26px',
        fontWeight: '400',
        margin: '0'
    },
    itemTotalPrice: {
        fontSize: '26px',
        fontWeight: '400',
        textAlign: 'right',
        margin: '0'
    },
    itemFooter:  {
        position: 'relative',
        marginTop: '30px !important',
        paddingBottom: '15px !important',
        paddingTop: '30px !important',
        "&:before": {
            content: '""',
            display: 'block',
            position: 'absolute',
            width: 'calc(100% - 30px)',
            height: '1px',
            backgroundColor: '#000000',
            top: '0'
        }
    }
};

export default checkoutStyle;
