import AuthApi from "../services/AuthApi";

// Constants
const initialState = {
    object: {}
}

// Types
const LOAD_MASTERS = 'LOAD_MASTERS'
const GET_MASTERS = 'GET_MASTERS'

// Reducers
export default function reducer(state = initialState, { type, payload }) {
    switch (type) {

    case LOAD_MASTERS:
        return { ...state, object: payload }

    default:
        return state
    }
}

// Actions
export const loadMasters = (_fields, _exclude) => async (dispatch) => {
    const fields = typeof _fields !== 'undefined' ? _fields : [];
    const exclude = typeof _exclude !== 'undefined' ? _exclude : [];
    try {
        const res = await AuthApi({
            method: "post",
            url: "/front_ws/call/",
            data: {
                id: null,
                name: "get_masters",
                session: "",
                args: {
                    fields,
                    exclude
                }
            },
            headers: {
                "Accept": "application/json"
            }
        });
        dispatch({
            type: LOAD_MASTERS,
            payload: res.data.response
        })
    } catch (error) {
        console.log(error);
    }
}

export const getMasters = () => (dispatch) => {
    dispatch({
        type: GET_MASTERS
    })
}