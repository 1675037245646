import { primaryColor } from "assets/jss/material-dashboard-pro-react.js";

const controlPanelStyle = {
    userName: {
        marginTop: '20px',
        marginBottom: '10px',
        fontFamily: 'inherit',
        fontWeight: '500',
        lineHeight: '1.1',
        letterSpacing: '0.005em',
        fontSize: '30px'
    },
    welcome: {
        marginTop: '20px',
        marginBottom: '10px',
        fontFamily: 'inherit',
        fontWeight: '500',
        lineHeight: '1.1',
        letterSpacing: '0.005em',
        fontSize: '24px'
    },
    bannerImage: {
        width: '100%',
        height: 'auto'
    },
    bannerContainer: {
        marginBottom: 15,
        marginTop: 15,
        width: '100%'
    },
    bannerContent: {
        display: 'flex',
        flex: '1 1 auto'
    },
    mainPanel: {
        marginTop: 15
    },
    cardTitle: {
        fontSize: '18px',
        fontWeight: '500',
        lineHeight: '1.1',
        margin: '30px 0 0 0',
        textAlign: 'center',
    },
    cardText: {
        textAlign: 'center',
    },
    cardHeader: {
        paddingBottom: 0
    },
    cardBody: {
        textAlign: 'center',
        paddingTop: 0
    },
    cardSpace: {
        marginTop: 15
    },
    cardRight: {
        height: '100%'
    },
    postContainer: {
        marginBottom: 30
    },
    postDate: {
        position: 'absolute',
        top: 0,
        left: '15px',
        backgroundColor: 'white',
        fontSize: '17px',
        fontWeight: 'bold',
        color: primaryColor[0],
        textAlign: 'center',
        padding: '5px',
        lineHeight: '17px',
        boxShadow: '5px 5px 5px rgb(0 0 0 / 30%)',
        textTransform: 'uppercase',
        zIndex: 1,
    },
    postHeader: {
        position: 'relative'
    },
    postImageBox: {
        maxHeight: '150px',
        overflowY: 'hidden',
    },
    postImage: {
        maxWidth: '100%',
        minHeight: '0',
    },
    postContent: {
        padding: '24px 0 16px 0',
        display: 'flex',
        flexDirection: 'column'
    },
    postTitle: {
        color: 'rgba(0, 0, 0, 0.87)',
        fontFamily: 'Roboto, RobotoDraft, "Helvetica Neue", sans-serif',
        fontSize: '14px',
        lineHeight: '20px',
        cursor: 'pointer'
    },
    courseImage: {
        maxWidth: '100%',
        minHeight: '0',
    },
    courseContent: {
        "& h3": {
            fontFamily: 'Roboto, RobotoDraft, "Helvetica Neue", sans-serif',
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '20px',
            "& small": {
                fontSize: '12px',
                lineHeight: '17px',
            }
        },
        "& h4": {
            fontFamily: 'Roboto, RobotoDraft, "Helvetica Neue", sans-serif',
            fontSize: '16px',
            fontWeight: '700',
            lineHeight: '22px'
        }
    },
    switchProfile: {
        marginBottom: '20px'
    },
    p0: {
        padding: '0 !important'
    },
    active: {
        color: primaryColor[0]
    },
    tableTitle: {
        fontFamily: 'inherit',
        fontWeight: '500',
        lineHeight: '1.1',
        letterSpacing: '0.005em',
        fontSize: '18px'
    },
    tableContainer: {
        paddingBottom: '30px !important',
        marginBottom: '30px !important',
        borderBottom: '1px solid #EEE',
        minHeight: 350
    },
    table: {
        width: '100%',
        flex: 1,
        "& thead > tr > th": {
            fontWeight: '400',
            lineHeight: '22px',
            letterSpacing: '0.005em',
            fontSize: '10px'
        },
        "& tbody > tr:nth-of-type(2n+1)": {
            backgroundColor: '#edf1f5'
        }
    },
    caption: {

    },
    up_main_container: {
        backgroundColor: '#fafafa',
        paddingBottom: '30px'
    },
    up_title: {
        textAlign: 'center',
        fontSize: '36px',
        fontWeight: '500',
        lineHeight: '39px',
        color: primaryColor[0],
        marginBottom: 0,
        marginTop: '20px'
    },
    up_subtitle: {
        textAlign: 'center',
        fontSize: '24px',
        fontWeight: '500',
        lineHeight: '26px',
        marginTop: '20px',
        marginBottom: '10px',
    },
    up_planes: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'stretch',
        marginTop: '30px',
        width: '100%'
    },
    up_plan: {
        display: 'flex',
        border: '1px solid ' + primaryColor[0],
        borderRadius: '8px',
        maxHeight: '100%',
        maxWidth: '25%',
        flexDirection: 'column',
        alignItems: 'center'
    },
    up_plan_active: {
        backgroundColor: primaryColor[0],
        "& h6": {
            color: 'white'
        },
        color: 'white'
    },
    up_plan_header: {
        flex: '1 1 auto',
        textAlign: 'center'
    },
    up_plan_title: {
        fontSize: '16px',
        fontWeight: '500',
        lineHeight: '17px',
        margin: '10px 0 16px 0'
    },
    up_subscription: {

    },
    up_price: {
        fontSize: '34px',
        fontWeight: '400',
        lineHeight: '24px'
    },
    up_period: {
        fontSize: '12px',
        fontWeight: '400',
        lineHeight: '24px'
    },
    up_features: {
        fontSize: '10px',
        lineHeight: '12px',
        padding: '15px'
    },
    up_feature: {
        position: 'relative',
        paddingLeft: 15,
        textAlign: 'left',
        paddingTop: '3px',
        paddingBottom: '3px'
    },
    checkIcon: {
        position: 'absolute',
        left: 0,
        top: '3px',
        width: '.5em',
        height: '.5em',
    },
    up_plan_button: {
        paddingLeft: '16px',
        paddingRight: '16px',
        marginBottom: '16px',
        color: 'rgba(0,0,0,0.87)',
        fontSize: '14px',
        backgroundColor: 'white',
        "&:hover, &:active, &:focus": {
            backgroundColor: 'white !important',
            color: 'rgba(0,0,0,0.87)',
        }
    },
    up_comprar_button: {
        marginTop: '30px',
        fontSize: '14px',
    },
    up_upgrade: {
        marginTop: '30px'
    },
    activateAccountDisabled: {
        opacity: '.5'
    },
    up_upgrade_title: {
        fontSize: '18px',
        textAlign: 'center',
        fontWeight: '500',
    },
    up_upgrade_subtitle: {
        fontSize: '16px',
        textAlign: 'center',
        fontWeight: '400',
    },
    accountIcon: {
        height: '128px',
        width: '128px',
        fill: '#4F5F7E'
    },
    up_activate_button: {
        marginTop: '20px',
        fontSize: '14px',
    },
    dialogTitle: {
        "& h2": {
            textAlign: 'center',
            fontSize: '24px',
            fontWeight: '700',
            color: '#2B374F'
        }
    },
    dialogText: {
        textAlign: 'center',
        fontSize: '14px',
        color: '#2B374F'
    },
    dialogActions: {
        justifyContent: 'center',
    },
};

export default controlPanelStyle;
