import React from "react";
import { trackPromise } from 'react-promise-tracker';
import { withRouter } from 'react-router-dom';
import { Formik, setIn } from "formik";
import PropTypes from 'prop-types';
import { getYupSchemaFromMetaData } from './yupSchemaCreator';
import moment from 'moment';
import { strings as translate } from "../../locale";

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// core components
import LoadingIndicator from "components/Loader/LoadingIndicator";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import Button from "../CustomButtons/Button";
import InputForm from "../CustomForms/InputForm";

// services
import MainServices from "../../services/MainServices";

import { get_language } from 'locale';

import styles from './styles';
import FormHelperText from "@material-ui/core/FormHelperText";
import useMainNotification from '../../hooks/useMainNotification';
import { set } from "lodash";

const useStyles = makeStyles(styles);

function Form(props) {
    const { data, editForm, name, loadErrorText, columns = 2, showCancel, onCancel, onSent, onSentError, popUpType } = props;
    const classes = useStyles();
    const [fields, setFields] = React.useState([]);
    const [requiredFields, setRequiredFields] = React.useState(false);
    const [initialValues, setInitialValues] = React.useState({});
    const [validationSchema, setValidationSchema] = React.useState({});
    const { addSuccess, addError } = useMainNotification();

    const [uniqueId, setUniqueId] = React.useState({});
    const [idField, setIdField] = React.useState('id');

    React.useEffect(() => {
        if(!data.metadata) return;
        const uniqueId = data.metadata.filter(item => item.unique).pop()

        setUniqueId(uniqueId)

        const idField = uniqueId ? uniqueId.accessor : 'id';
        setIdField(idField);

        // filtarmos los campos utiles
        const fields = data.metadata
            .filter(item=>(((!editForm && item.accessor !== idField) || editForm) && item.accessor !== 'actions'));
        setFields(fields);

        // establecemos los valores iniciales, para la edición hay que obtenerlos del data.data
        const getInitialValues = () => {
            const values = {};
            const fData = data && data[0] ? data[0] : (data && data.global_action && data.global_action.data) ? data.global_action.data : data;
            if ( !editForm ) {
                fields.forEach(item => {
                    Object.assign(values, {[item.accessor]: (item.type === "checkbox") ? false : fData[item.accessor] ? fData[item.accessor] : ''})
                })
            } else {
                fields.forEach(item => {
                    if(data.global_action && fData.global_action.is_confirm) {
                        Object.assign(values, {[item.accessor]: typeof fData[item.accessor] === 'boolean' ? !fData[item.accessor] : fData[item.accessor]})
                    } else {
                        // console.log(item.accessor, fData[item.accessor]);
                        Object.assign(values, {[item.accessor]: item.type === 'select' ? '' : fData[item.accessor]})
                    }
                })
                // formateamos los campos tipo date
                moment.locale(get_language);
                data.metadata.filter(item=>item.type === 'date').forEach( item => {
                    values[item.accessor] = moment(values[item.accessor]).format('L');
                })
            }
            return values;
        }

        setInitialValues(getInitialValues());

        // establecemos las validaciones
        setValidationSchema(getYupSchemaFromMetaData(data.metadata, [], []));

        // buscamos campos requeridos
        const hasRequiredFields = () => {
            let required = false;
            fields.forEach( item => {
                if (!item.validations){ return; }
                item.validations.forEach( v => {
                    if (v.type === 'required') { required = true; }
                })
            })
            return required;
        }
        setRequiredFields( hasRequiredFields() )
    }, [editForm, data]);

    const handleFormSubmit = async (values, actions) => {
        try {
            const newValues = Object.assign(popUpType ? data : {}, values);

            // formateamos los campos tipo date
            data.metadata.filter(item=>item.type === 'date').forEach( item => {
                if (/^\d{2}\/\d{2}\/\d{4}$/.test(values[item.accessor])) {
                    const input_format = get_language === "es" ? 'DD/MM/YYYY' : 'MM/DD/YYYY';
                    newValues[item.accessor] = moment(values[item.accessor], input_format).format('YYYY-MM-DD');
                }
            })
            // si el input image contiene una url, borramos la clave para que no se actualice
            data.metadata.filter(item=>item.type === 'image').forEach( item => {
                if (typeof values[item.accessor] === 'string' && values[item.accessor].lastIndexOf('https') === 0) {
                    delete newValues[item.accessor];
                }
            })

            let res = null;
            if(popUpType === 'row') {
                res = await MainServices.editPopup( name, newValues, data.global_action );
            } else if(popUpType === 'form') {
                res = await MainServices.addPopup( name, newValues, data.global_action );
            } else if (!editForm) {
                res = await MainServices.add( name, newValues );
            } else {
                res = await MainServices.edit( name, newValues );
            }

            if(!res.error || !res.data.error) {
                addSuccess(!editForm ? 'Registro agregado exitosamente' : 'Registro editado exitosamente');
            }

            if(popUpType) {
                handleSent(values);
            } else {
                setTimeout(() => {
                    props.history.push(`/admin/${name}`);
                }, 200)
            }
            return res;
        } catch (e) {
            const data = e.response ? e.response.data : {};
            let errors = {};

            Object.keys(data).forEach(item => {
                errors = setIn(errors, item, data[item][0]);
            });
            
            actions.setStatus(errors);

            handleSentError(e.response)

            if(e.message) {
                addError(e.message, null, {messageLength:null});
                MainServices.cancel();
            }
        } finally {
            actions.setSubmitting(false);
        }
    }

    const handleCancel = () => {
        if(onCancel) {
            onCancel();
        }
    }

    const handleSent = (values) => {
        if(onSent) {
            onSent(values);
        }
    }

    const handleSentError = (values) => {
        if(onSentError) {
            onSentError(values);
        }
    }
    
    return ( ((editForm && initialValues[idField] ) || !editForm) &&
    <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
            trackPromise(handleFormSubmit(values, actions), 'save-button');
        }}
    >
        {formik => (
            <form onSubmit={formik.handleSubmit}>
                <GridContainer alignItems="stretch">
                    {fields.map((item, index) => {
                        if ((!editForm && item.accessor === idField) || item.type === 'link') {
                            return null;
                        }

                        if( data.global_action && (data.global_action.is_confirm || (!data.global_action.is_confirm && item.accessor === 'id')) ) {
                            return <input key={index} name={item.accessor} id={item.accessor} type="hidden" value={initialValues[item.accessor] || ''} />
                        }

                        return (<GridItem xs={12} sm={12/columns} key={index}>
                            <InputForm data={item}/>
                        </GridItem>)
                    })}
                </GridContainer>
                <GridContainer>
                    {requiredFields && <GridItem xs={12}>
                        <div className={classes.formCategory}>
                            <small>*</small> {translate.required_fields}
                        </div>
                    </GridItem>}
                    <GridItem xs={12} container justify="center">
                        {data.metadata ?
                            <>
                                <div className={classes.formButtonWithLoader}>
                                    <LoadingIndicator
                                        type="TailSpin"
                                        color="#2e82ef"
                                        height={30}
                                        width={30}
                                        area="save-button"
                                    />
                                    {
                                        data.global_action && data.global_action.is_confirm &&
                                        <Button
                                            type="submit"
                                            color="primary"
                                        >
                                            {translate.save}
                                        </Button>
                                    }
                                    {
                                        (!data.global_action || (data.global_action && !data.global_action.is_confirm)) &&
                                        <Button
                                            type="submit"
                                            color="primary"
                                            disabled={(!(formik.isValid && formik.dirty) || formik.isSubmitting)}
                                        >
                                            {editForm ? translate.save : translate.save}
                                        </Button>
                                    }
                                </div>

                                {showCancel && <Button 
                                    type="button"
                                    color="info"
                                    onClick={handleCancel}
                                >{translate.cancel}</Button>}
                            </>
                            :
                            <FormHelperText
                                className={classes.labelRootError + ' ' + classes.textCenter}
                            >
                                {loadErrorText ? loadErrorText : translate.formatString(translate.autogenerate_form_error, <br />) }
                            </FormHelperText>
                        }
                    </GridItem>
                </GridContainer>
            </form>
        )}
    </Formik>
    );
}

Form.prototype = {
    data: PropTypes.object,
    editForm: PropTypes.bool,
    name: PropTypes.string,
    loadErrorText: PropTypes.string,
    showCancel: PropTypes.bool,
    onClick: PropTypes.func,
    onSent: PropTypes.func,
    onSentError: PropTypes.func,
    popUpType: PropTypes.oneOf([
        'row',
        'form'
    ])
}

export default withRouter(Form);
