import axios from "axios";

// constantes
const dataInicial = {
    array: [],
    activationData: {
        subscription_category: null,
        category: null,
        id: null
    }
}

// types
const GET_USER_SUCCESS = 'GET_USER_SUCCESS'
const SET_ACTIVATION_DATA = 'SET_ACTIVATION_DATA'

// reducer
export default function reducer(state = dataInicial, action){
    switch(action.type){
        case GET_USER_SUCCESS:
            return {...state, array: action.payload}
        case SET_ACTIVATION_DATA:
            return {...state, activationData: action.payload}
        default:
            return state
    }
}

// actions
export const getCoaches = () => async (dispatch, getState) => {
    try {
        const res = await axios.get('https://pokeapi.co/api/v2/pokemon?offset=0&limit=20')
        dispatch({
            type: GET_USER_SUCCESS,
            payload: res.data.results
        })
    } catch (error) {
        console.log(error)
    }
}

export const setActivationData = (selectedPlan) => async (dispatch) => {
    dispatch({
        type: SET_ACTIVATION_DATA,
        payload: selectedPlan
    })
}