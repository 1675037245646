import React from 'react';

import GenericPageProvider from "../../providers/GenericPageProvider";

import List from './List';
import Edit from './Edit';
import Add from './Add';

function GenericPage(props) {
    const {config} = props;
    const model = config.path.replace("/", "");
    const location = props.location.pathname;
    const newConfig = Object.assign(config, {model});

    if ((location.lastIndexOf(`/${model}/add`) !== -1)) {
        return (
          <GenericPageProvider config={newConfig}>
            <Add />
          </GenericPageProvider>
        );
    }

    if ((location.lastIndexOf(`/${model}/edit`) !== -1)) {
        const regexp = new RegExp(`^/admin/${model}/edit/(\\d+)`, 'i')
        const match = location.match(regexp).pop()
        const id = match ? match : null
        return (
          <GenericPageProvider config={newConfig}>
            <Edit id={id} />
          </GenericPageProvider>
        );
    }

    return (
      <GenericPageProvider config={newConfig}>
          <List />
      </GenericPageProvider>
    );
}

export default GenericPage;
