import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import _ from "lodash";
import { trackPromise } from 'react-promise-tracker';
import LoadingIndicator from 'components/Loader/LoadingIndicator';
import { Fade, makeStyles } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/Help';
import ClearIcon from '@material-ui/icons/Clear';

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button'
import CustomSwitch from 'components/CustomSwitch/CustomSwitch'

import SubscriptionServices from '../../services/SubscriptionServices'

import pricingStyle from 'assets/jss/material-dashboard-pro-react/components/pricingStyle';

const useStyles = makeStyles(pricingStyle);

const Pricing = ({
    data,
    onClick
}) => {

    const classes = useStyles();
    const [isMonthly, setIsMonthly] = useState(true)
    const [monthlySubscriptions, setMonthlySubscriptions] = useState([])
    const [yearlySubscriptions, setYearlySubscriptions] = useState([])
    const [suscriptionCategory, setSuscriptionCategory] = useState([])
    const [selectedPlan, setSelectedPlan] = useState({})

    useEffect(() => {
        setMonthlySubscriptions(data.filter(item => item.subscription_category === 'Monthly'))
        setYearlySubscriptions(data.filter(item => item.subscription_category === 'Year'))
        setSuscriptionCategory(data.filter(item => item.subscription_category === 'Monthly'))
    }, [data])

    const handleSwitchChange = (yearly) => {
        setIsMonthly(!yearly)
        setSuscriptionCategory(yearly ? yearlySubscriptions : monthlySubscriptions)
    }

    const handleButtonClick = (plan) => {
        setSelectedPlan(plan)
        if(onClick) {
            onClick(plan)
        }
    }

    const classActive = (category, className) => {
        const subscription_category = isMonthly ? 'Monthly' : 'Year'
        if (
            selectedPlan.category === category &&
            selectedPlan.subscription_category === subscription_category
        ) {
            return ' ' + classes[className+'Active']
        }
        return ''
    }

    return (data && <div className={classes.root}>
        <CustomSwitch
            labelLeft="Mensual"
            labelRight="Anual"
            onChange={handleSwitchChange}
            rootProps={{
                className: classes.switch
            }}
        />
        <div className={classes.container}>
            <GridContainer direction="row" justify="space-between" alignItems="stretch" className={classes.rowBackground}>
                <GridItem xs={3}></GridItem>
                <GridItem xs={3} className={classes.backgroundItem+classActive('basic', 'backgroundItem')}></GridItem>
                <GridItem xs={3} className={classes.backgroundItem+classActive('premium', 'backgroundItem')}></GridItem>
                <GridItem xs={3} className={classes.backgroundItem+classActive('platinum', 'backgroundItem')}></GridItem>
            </GridContainer>
            <GridContainer direction="row" justify="space-between" alignItems="stretch" className={classes.row}>
                <GridItem xs={3}></GridItem>
                { suscriptionCategory.map((plan, key)=>(
                <GridItem key={key} xs={3}>
                    <div className={classes.cell + ' ' + classes.cellCenter+classActive(plan.category, 'cellCenter')}>
                        <h4>{ plan.category.toUpperCase() }</h4>
                        <h5>${ plan.price}</h5>
                        {isMonthly && <h6>/mensual</h6>}
                        {!isMonthly && <h6>/anual</h6>}
                    </div>
                </GridItem>
                ))}
            </GridContainer>
            <GridContainer direction="row" justify="space-between" alignItems="stretch" className={classes.rowWithSeparator}>
                <GridItem xs={3}>
                    <div className={classes.cell + ' ' + classes.cellLeft}>Atletas por cuentas</div>
                </GridItem>
                <GridItem xs={3}>
                    <div className={classes.cell + ' ' + classes.cellCenter+classActive('basic', 'cellCenter')}>Ilimitado</div>
                </GridItem>
                <GridItem xs={3}>
                    <div className={classes.cell + ' ' + classes.cellCenter+classActive('premium', 'cellCenter')}>Ilimitado</div>
                </GridItem>
                <GridItem xs={3}>
                    <div className={classes.cell + ' ' + classes.cellCenter+classActive('platinum', 'cellCenter')}>Ilimitado</div>
                </GridItem>
            </GridContainer>
            <GridContainer direction="row" justify="space-between" alignItems="stretch" className={classes.rowWithSeparator}>
                <GridItem xs={3}>
                    <div className={classes.cell + ' ' + classes.cellLeft}>Análisis de datos</div>
                </GridItem>
                <GridItem xs={3}>
                    <div className={classes.cell + ' ' + classes.cellCenterHorizontal+classActive('basic', 'cellCenter')}>
                        Standard
                        <Tooltip title="Análisis del plan de entrenamiento por volumen" placement="bottom">
                            <HelpIcon fontSize="small" className={classes.helpIcon} />
                        </Tooltip>
                    </div>
                </GridItem>
                <GridItem xs={3}>
                    <div className={classes.cell + ' ' + classes.cellCenterHorizontal+classActive('premium', 'cellCenter')}>
                        Avanzado
                        <Tooltip title="Análisis del plan de entrenamiento por volumen, rendimiento, intensidad, carga, tiempo en zonas, métricas, picos. Análisis de la sesión de entrenamiento" placement="bottom">
                            <HelpIcon fontSize="small" className={classes.helpIcon} />
                        </Tooltip>
                    </div>
                </GridItem>
                <GridItem xs={3}>
                    <div className={classes.cell + ' ' + classes.cellCenterHorizontal+classActive('platinum', 'cellCenter')}>
                        Experto
                        <Tooltip title="Análisis de datos por intervalo de tiempo. Comparación de atletas" placement="bottom">
                            <HelpIcon fontSize="small" className={classes.helpIcon} />
                        </Tooltip>
                    </div>
                </GridItem>
            </GridContainer>
            <GridContainer direction="row" justify="space-between" alignItems="stretch" className={classes.rowWithSeparator}>
                <GridItem xs={3}>
                <div className={classes.cell + ' ' + classes.cellLeft}>Entrenadores colaboradores</div>
                </GridItem>
                <GridItem xs={3}>
                    <div className={classes.cell + ' ' + classes.cellCenter+classActive('basic', 'cellCenter')}>
                        <ClearIcon fontSize="small" className={classes.clearIcon} />
                    </div>
                </GridItem>
                <GridItem xs={3}>
                    <div className={classes.cell + ' ' + classes.cellCenter+classActive('premium', 'cellCenter')}>2</div>
                </GridItem>
                <GridItem xs={3}>
                    <div className={classes.cell + ' ' + classes.cellCenter+classActive('platinum', 'cellCenter')}>5</div>
                </GridItem>
            </GridContainer>
            <GridContainer direction="row" justify="space-between" alignItems="stretch" className={classes.row}>
                <GridItem xs={3}>
                <div className={classes.cell + ' ' + classes.cellLeft}>Beneficios</div>
                </GridItem>
                <GridItem xs={3}>
                <div className={classes.cell + ' ' + classes.cellCenter+classActive('basic', 'cellCenter')}>
                    10% OFF<br />
                    Cursos University
                </div>
                </GridItem>
                <GridItem xs={3}>
                <div className={classes.cell + ' ' + classes.cellCenter+classActive('premium', 'cellCenter')}>
                    20% OFF<br />
                    Cursos University
                </div>
                </GridItem>
                <GridItem xs={3}>
                <div className={classes.cell + ' ' + classes.cellCenter+classActive('platinum', 'cellCenter')}>
                    35% OFF<br />
                    Cursos University
                </div>
                </GridItem>
            </GridContainer>
            <GridContainer direction="row" justify="space-between" alignItems="stretch" className={classes.row}>
                <GridItem xs={3}>
                </GridItem>
                <GridItem xs={3}>
                <div className={classes.cell + ' ' + classes.cellCenter}>
                    <Button
                        className={classes.button+classActive('basic', 'button')}
                        color="primary"
                        size="sm"
                        variant="outlined"
                        onClick={()=>{handleButtonClick(suscriptionCategory[0])}}
                    >
                        COMPRAR
                    </Button>
                </div>
                </GridItem>
                <GridItem xs={3}>
                <div className={classes.cell + ' ' + classes.cellCenter}>
                    <Button
                        className={classes.button+classActive('premium', 'button')}
                        color="primary"
                        size="sm"
                        variant="outlined"
                        onClick={()=>{handleButtonClick(suscriptionCategory[1])}}
                    >
                        COMPRAR
                    </Button>
                </div>
                </GridItem>
                <GridItem xs={3}>
                <div className={classes.cell + ' ' + classes.cellCenter}>
                    <Button
                        className={classes.button+classActive('platinum', 'button')}
                        color="primary"
                        size="sm"
                        variant="outlined"
                        onClick={()=>{handleButtonClick(suscriptionCategory[2])}}
                    >
                        COMPRAR
                    </Button>
                </div>
                </GridItem>
            </GridContainer>
        </div>
    </div>)
}

Pricing.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    onClick: PropTypes.func
}

export default Pricing
