import React from 'react'
import AuthServices from 'services/AuthServices';

export const UserContext = React.createContext({
    user: {},
    updateUser: () => {}
});

const UserProvider = (props) => {

    const [user, setUser] = React.useState();

    const getCurrentUser = () => {
        const currentUser = AuthServices.getUser();

        if (currentUser) {
            setUser(currentUser);
        }
    }

    React.useEffect(() => {
        getCurrentUser()
    }, [])

    const contextValue = {
        user,
        updateUser: React.useCallback(()=>getCurrentUser(), [])
    }

    return (
        <UserContext.Provider value={contextValue}>
            {props.children}
        </UserContext.Provider>
    )
}

export default UserProvider
