import React, { useEffect, useState } from 'react'

import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Button from 'components/CustomButtons/Button'
import { Card, Fade, makeStyles, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core'

const AthletePanel = () => {
    return (
        <Fade in>
            <GridContainer>
                <GridItem>
                    Athlete Content
                </GridItem>
            </GridContainer>
        </Fade>
    )
}

export default AthletePanel
