import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router';
import _ from "lodash";
import { trackPromise } from 'react-promise-tracker';
import LoadingIndicator from 'components/Loader/LoadingIndicator';
import { useDispatch } from 'react-redux'
import { setActivationData } from '../../redux/coaches'

import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fade, makeStyles } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import SubscriptionServices from '../../services/SubscriptionServices'

import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Button from 'components/CustomButtons/Button'
import Pricing from 'components/Pricing/Pricing'

import controlPanelStyle from 'assets/jss/material-dashboard-pro-react/views/controlPanelStyle'
import classNames from 'classnames';

const useStyles = makeStyles(controlPanelStyle);

const UserPanel = (props) => {
    const classes = useStyles();
    const [isMounted, setIsMounted] = useState(true)
    const [isLoading, setIsLoading] = useState(true)
    const [monthlySubscriptions, setMonthlySubscriptions] = useState([])
    const [suscriptionPlans, setSuscriptionPlans] = useState([])
    const [selectedPlan, setSelectedPlan] = useState({})
    const [showBtnComprar, setShowBtnComprar] = useState(true)
    const [openPricingDialog, setOpenPricingDialog] = useState(false)
    const dispatch = useDispatch()

    useEffect(() => {
        sessionStorage.removeItem('selectedPlan');
        const getData = async () => {
            try {            
                const res = await SubscriptionServices.getSubscriptionPlans();
                if (res.data.response) {
                    const subscriptions = res.data.response;
                    const sorted = _.sortBy(subscriptions, ['price']).pop();
                    setSuscriptionPlans(sorted);
                    if (isMounted) {
                        setMonthlySubscriptions(sorted.filter(item => item.subscription_category === 'Monthly'))
                    }
                }
            } catch (error) {
                console.error(error);
                setIsLoading(false)
            }
        }

        trackPromise(getData(), 'user').then(() => {
            setIsLoading(false)
        });
        return () => {
            setIsMounted(false);
        }
    }, [])

    const selectPlan = (subscription_category, category, id) => {
        // oculta/muestra el botón comprar
        // si presiona 2 veces el mismo boton usamos la funcionalidad toggle
        // si es un nuevo id ocultamos el botón
        const _showBtnComprar = id === selectedPlan.id ? !showBtnComprar : false
        const data =  {
            subscription_category: null,
            category: null,
            id: null
        }

        if (!_showBtnComprar){
            _.assign(data, {
                subscription_category,
                category,
                id
            })
        }

        setShowBtnComprar(_showBtnComprar);

        setSelectedPlan(data)
        dispatch(setActivationData(data));

        if(_showBtnComprar === false) {
            setTimeout(()=>{
                document.getElementById("ActivateAccount").scrollIntoView({ alignToTop: true, block: 'start', behavior: "smooth" })
            }, 200)
        }
    }

    const classesActivateAccount = classNames({
        [classes.activateAccountDisabled]: !selectedPlan.category
    })

    const handleActivateAccount = () => {
        props.history.push('/admin/new-profile')
        alert('/admin/new-profile')
    }

    const handleBuyClick = () => {
        setOpenPricingDialog(true)
    }

    const handleClosePricingDialog = () => {
        setOpenPricingDialog(false);
    };

    const handlePricingBuyClick = (plan) => {
        console.log(plan.category, plan.subscription_category);
        sessionStorage.setItem('selectedPlan', JSON.stringify(plan));
        props.history.push('/admin/checkout')
    }

    return (
        <>
            <LoadingIndicator
                type="TailSpin"
                color="#2e82ef"
                area="user"
                height={50}
                width={50}
            />
            { !isLoading &&
                <Fade in>
                    <>
                        <GridContainer className={classes.up_main_container}>
                            <GridItem xs={12}>
                                <h4 className={classes.up_title}>¡Prueba 30 días gratis!</h4>
                                <h5 className={classes.up_subtitle}>Sin datos de tarjeta</h5>
                                
                                {/* START PLANES */}
                                <div className={classes.up_planes}>
                                    {monthlySubscriptions.map((plan, key) => (
                                        <div key={key} className={classes.up_plan + ' ' + (plan.subscription_category === selectedPlan.subscription_category && plan.category === selectedPlan.category ? classes.up_plan_active : null)}>
                                            <div className={classes.up_plan_header}>
                                                <h6 className={classes.up_plan_title}>{plan.category.toUpperCase()}</h6>
                                                <div className={classes.up_subscription}>
                                                    <div className={classes.up_price}>${plan.price}</div>
                                                    <div className={classes.up_period}>/mensual</div>
                                                </div>
                                                {plan.category === 'basic' &&
                                                    <div className={classes.up_features}>
                                                        <div className={classes.up_feature}>
                                                            <CheckIcon className={classes.checkIcon} /> Atletas ilimitados
                                                        </div>
                                                        <div className={classes.up_feature}>
                                                            <CheckIcon className={classes.checkIcon} /> Análisis de datos Estándar
                                                        </div>
                                                        <div className={classes.up_feature}>
                                                            <CheckIcon className={classes.checkIcon} /> 10% OFF Cursos University
                                                        </div>
                                                    </div>
                                                }
                                                {plan.category === 'premium' &&
                                                    <div className={classes.up_features}>
                                                        <div className={classes.up_feature}>
                                                            <CheckIcon className={classes.checkIcon} /> Atletas ilimitados
                                                        </div>
                                                        <div className={classes.up_feature}>
                                                            <CheckIcon className={classes.checkIcon} /> Análisis de datos Avanzado
                                                        </div>
                                                        <div className={classes.up_feature}>
                                                            <CheckIcon className={classes.checkIcon} /> Colaboradores 2
                                                        </div>
                                                        <div className={classes.up_feature}>
                                                            <CheckIcon className={classes.checkIcon} /> 20% OFF Cursos University
                                                        </div>
                                                    </div>
                                                }
                                                {plan.category === 'platinum' &&
                                                    <div className={classes.up_features}>
                                                        <div className={classes.up_feature}>
                                                            <CheckIcon className={classes.checkIcon} /> Atletas ilimitados
                                                        </div>
                                                        <div className={classes.up_feature}>
                                                            <CheckIcon className={classes.checkIcon} /> Análisis de datos Experto
                                                        </div>
                                                        <div className={classes.up_feature}>
                                                            <CheckIcon className={classes.checkIcon} /> Colaboradores 5
                                                        </div>
                                                        <div className={classes.up_feature}>
                                                            <CheckIcon className={classes.checkIcon} /> 35% OFF Cursos University
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <Button
                                                className={classes.up_plan_button}
                                                color="primary"
                                                size="sm"
                                                variant="outlined"
                                                onClick={() => selectPlan(plan.subscription_category, plan.category, plan.id)}
                                            >
                                                PROBAR
                                            </Button>
                                        </div>
                                    ))}
                                </div>
                                {/* END PLANES */}
                            </GridItem>

                            {showBtnComprar &&
                            <GridItem xs={12} container justify="center">
                                <Button
                                    className={classes.up_comprar_button}
                                    color="primary"
                                    size="sm"
                                    variant="outlined"
                                    onClick={handleBuyClick}
                                >
                                    COMPRAR
                                </Button>
                            </GridItem>
                            }
                        </GridContainer>
                        <GridContainer justify="center" className={classes.up_upgrade} id="ActivateAccount">
                            <GridItem 
                                xs={12} 
                                sm={6} 
                                container 
                                direction="column" 
                                justify="center"
                                
                            >
                                <h4 className={classes.up_upgrade_title}>Completa tu perfil de entrenador</h4>
                                <h5 className={classes.up_upgrade_subtitle}>Recuerda que más completo, ¡más info tendrán los atletas para elegirte!</h5>
                                <GridContainer
                                    direction="column"
                                    alignItems="center"
                                    className={classesActivateAccount}
                                >
                                    <AccountCircleIcon className={classes.accountIcon} />
                                    <Button
                                        color="primary"
                                        size="sm"
                                        className={classes.up_activate_button}
                                        disabled={!selectedPlan.category}
                                        onClick={handleActivateAccount}
                                    >
                                        ACTIVA TU CUENTA
                                    </Button>
                                </GridContainer>
                            </GridItem>
                        </GridContainer>
                        
                        <Dialog
                            fullWidth
                            maxWidth="md"
                            open={openPricingDialog}
                            onClose={handleClosePricingDialog}
                            aria-labelledby="pricingDialogTitle"
                            aria-describedby="pricingDialogDescription"
                            scroll="body"
                        >
                            <DialogTitle id="pricingDialogTitle" className={classes.dialogTitle}>ELIGE EL PLAN A TU MEDIDA</DialogTitle>
                            <DialogContent>
                                <DialogContentText className={classes.dialogText}>Compra directa sin prueba gratuita de 30 días</DialogContentText>
                                <Pricing
                                    data={suscriptionPlans}
                                    onClick={handlePricingBuyClick}
                                />
                            </DialogContent>
                            <DialogActions className={classes.dialogActions}>
                                <Button 
                                    onClick={handleClosePricingDialog} 
                                    color="primary" 
                                    size="sm"
                                >
                                    Cancelar
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </>
                </Fade>
            }
        </>
    )
}

export default withRouter(UserPanel)
