import React, { useState } from 'react'
import classNames from "classnames";

import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Switch from '@material-ui/core/Switch';

import { makeStyles, withStyles, Typography } from '@material-ui/core'

import { primaryColor } from 'assets/jss/material-dashboard-pro-react.js';

const useStyles = makeStyles({
    p0: {
        padding: '0 !important'
    },
    active: {
        color: primaryColor[0]
    },
});

const OrangeSwitch = withStyles({
    switchBase: {
        color: primaryColor[0],
        '&$checked': {
            color: primaryColor[0],
        },
        '&$checked + $track': {
            backgroundColor: primaryColor[0],
        },
    },
    checked: {},
    track: {},
})(Switch);

const CustomSwitch = ({
    labelLeft = 'left',
    labelRight = 'right',
    onChange,
    rootProps
}) => {
    const [left, setLeft] = useState(true);
    const classes = useStyles();

    const leftClass = classNames({
        [classes.p0]: true,
        [classes.active]: left === true
    })

    const rightClass = classNames({
        [classes.p0]: true,
        [classes.active]: left === false
    })

    const handleSwitchChange = (event) => {
        setLeft(!event.target.checked)
        if(onChange){ onChange(event.target.checked) }
    }
    return (
        <GridContainer {...rootProps}>
            <GridItem container justify="center">
                <Typography component="div">
                    <GridItem component="label" container alignItems="center">
                        <GridItem className={leftClass}>{labelLeft}</GridItem>
                        <GridItem className={classes.p0}>
                            <OrangeSwitch
                                checked={!left}
                                onChange={handleSwitchChange}
                                name="userProfile"
                            />
                        </GridItem>
                        <GridItem className={rightClass}>{labelRight}</GridItem>
                    </GridItem>
                </Typography>
            </GridItem>
        </GridContainer>
    )
}

export default CustomSwitch
