import ErrorPage from "views/Pages/ErrorPage.js";
import LoginPage from "views/Pages/LoginPage.js";
import SignUpPage from "views/Pages/SignUpPage.js";
import SignUpCompletePage from "views/Pages/SignUpCompletePage.js";
import { Role } from "helpers/role";

// @material-ui/icons
import GenericPage from "./views/GenericPage/GenericPage";
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import Iframe from "./views/GenericPage/Iframe";
import ControlPanel from "./views/ControlPanel/ControlPanel";
import NewProfile from "./views/ControlPanel/NewProfile";
import Checkout from "./views/Checkout/Checkout";

const dashRoutes = [
  {
    path: "/control-panel",
    name: "E-Tool",
    icon: AccessibilityIcon,
    component: Iframe,
    layout: "/et",
    roles: [Role.Admin],
    redirect: true,
  },
  {
    path: "/src/et/",
    name: "E-Tool",
    icon: AccessibilityIcon,
    component: Iframe,
    layout: "/et",
    roles: [Role.Admin],
    redirect: true,
  },
  {
    path: "/my-athlete",
    name: "Mi negocio",
    icon: AccessibilityIcon,
    component: GenericPage,
    layout: "/admin",
    roles: [Role.Admin]
  },
  { // este se llama desde angular
    path: "/my-athlete",
    name: "Mi negocio (IFramed)",
    icon: AccessibilityIcon,
    component: GenericPage,
    layout: "/iframe",
    roles: [Role.Admin]
  },
  {
    path: "/:page",
    name: "E-Tool",
    icon: AccessibilityIcon,
    component: Iframe,
    layout: "/et",
    roles: [Role.Admin],
    redirect: true,
  },
  {
    path: "",
    name: "E-Tool",
    icon: AccessibilityIcon,
    component: Iframe,
    layout: "/et",
    roles: [Role.Admin],
    redirect: true,
  },
  {
    path: "/:page/:id?",
    name: "University",
    icon: AccessibilityIcon,
    component: Iframe,
    layout: "/et-academy",
    redirect: true,
    roles: [Role.Admin]
  },
  {
    path: "",
    name: "University",
    icon: AccessibilityIcon,
    component: Iframe,
    layout: "/et-academy",
    redirect: true,
    roles: [Role.Admin]
  },
  {
    path: "/control-panel",
    name: "Panel de Inicio",
    component: ControlPanel,
    layout: "/admin",
    redirect: true,
    sidebar: false
  },
  {
    path: "/new-profile",
    name: "Panel de Inicio",
    component: NewProfile,
    layout: "/admin",
    redirect: true,
    sidebar: false
  },
  {
    path: "/checkout",
    name: "Panel de Inicio",
    component: Checkout,
    layout: "/admin",
    redirect: true,
    sidebar: false
  },
  {
    path: "/login",
    name: "Login",
    component: LoginPage,
    layout: "/auth",
    redirect: true
  },
  {
    path: "/signup",
    name: "Sign up",
    component: SignUpPage,
    layout: "/auth",
    redirect: true
  },
  {
    path: "/signup-complete",
    name: "Sign up",
    component: SignUpCompletePage,
    layout: "/auth",
    redirect: true
  },
  {
    path: "/error",
    name: "Error Page",
    component: ErrorPage,
    layout: "/auth",
    redirect: true
  },
];

export default dashRoutes;