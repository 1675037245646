import React from "react";
import { trackPromise } from 'react-promise-tracker';
import { withRouter } from 'react-router-dom';
import { Formik, setIn } from "formik";
import PropTypes from 'prop-types';
import { getYupSchemaFromMetaData } from '../CustomForms/yupSchemaCreator';
import moment from 'moment';
import { strings as translate } from "../../locale";

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// core components
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import Button from "../CustomButtons/Button";
import InputForm from "../CustomForms/InputForm";

import { get_language } from 'locale';

import styles from './styles';

const useStyles = makeStyles(styles);

function GlobalFilters(props) {
    const { data, editForm, name, loadErrorText, columns = 4, onSubmit, onCancel } = props;
    const classes = useStyles();
    const [fields, setFields] = React.useState([]);
    const [initialValues, setInitialValues] = React.useState({});
    const [validationSchema, setValidationSchema] = React.useState({});
    const [uniqueId, setUniqueId] = React.useState(null);
    const [isFiltered, setIsFiltered] = React.useState(false);

    const [idField, setIdField] = React.useState('id');

    React.useEffect(() => {
        if(!data || !data.filter || !data.filter.form) return;
        
        const uniqueId = data.filter.form.filter(item => item.unique).pop()
        setUniqueId(uniqueId)
        // filtarmos los campos utiles
        const fields = data.filter.form
            .filter(item=>(((!editForm && item.accessor !== 'id') || editForm) && item.accessor !== 'actions'));
        setFields(fields);
        const idField = uniqueId ? uniqueId.accessor : 'id';
        setIdField(idField);

        // establecemos los valores iniciales, para la edición hay que obtenerlos del data.data
        const getInitialValues = () => {
            const values = {};
            fields.forEach(item => {
                Object.assign(values, {[item.accessor]: (item.type === "checkbox") ? false : ''})
            })
            return values;
        }

        setInitialValues(getInitialValues());
        // establecemos las validaciones
        setValidationSchema(getYupSchemaFromMetaData(data.filter.form, [], []));

    }, [editForm, data]);

    const handleFormSubmit = (values, actions) => {
        const newValues = Object.assign({}, values);

        // formateamos los campos tipo date
        data.filter.form.filter(item=>item.type === 'date').forEach( item => {
            if (/^\d{2}\/\d{2}\/\d{4}$/.test(values[item.accessor])) {
                const input_format = get_language === "es" ? 'DD/MM/YYYY' : 'MM/DD/YYYY';
                newValues[item.accessor] = moment(values[item.accessor], input_format).format('YYYY-MM-DD');
            }
        })
        // si el input image contiene una url, borramos la clave para que no se actualice
        data.filter.form.filter(item=>item.type === 'image').forEach( item => {
            if (typeof values[item.accessor] === 'string' && values[item.accessor].lastIndexOf('https') === 0) {
                delete newValues[item.accessor];
            }
        })
        actions.setSubmitting(false);
        if(onSubmit) {
            onSubmit(newValues);
            setIsFiltered(true);
        }
    }

    const handleFormCancel = (e, resetForm) => {
        if(onCancel) {
            onCancel();
            setIsFiltered(false);
        }
        resetForm();
    }
    
    return (
    <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
            handleFormSubmit(values, actions);
        }}
    >
        {formik => (
            <form onSubmit={formik.handleSubmit}>
                <GridContainer className={classes.globalFilterForm}>
                    <GridItem xs={12} md={10}>
                        <GridContainer alignItems="stretch">
                            {fields.map((item, index) => {
                                if ((!editForm && item.accessor === idField) || item.type === 'link') {
                                    return null;
                                }

                                if( data.global_action && (data.global_action.is_confirm || (!data.global_action.is_confirm && item.accessor === 'id')) ) {
                                    return <input key={index} name={item.accessor} id={item.accessor} type="hidden" />
                                }

                                return (<GridItem xs={12} sm={12/columns} key={index}>
                                    <InputForm data={item}/>
                                </GridItem>)
                            })}
                        </GridContainer>
                    </GridItem>
                    <GridItem xs={12} md={2}>
                        {fields.length > 0 &&
                            <>
                                <Button
                                    type="submit"
                                    color="primary"
                                    disabled={(!(formik.isValid && formik.dirty) || formik.isSubmitting)}
                                    className={classes.globalFilterFormButton}
                                >
                                    {translate.search}
                                </Button>
                                {isFiltered && <Button
                                    type="button"
                                    color="white"
                                    disabled={(!(formik.isValid && formik.dirty) || formik.isSubmitting)}
                                    className={classes.globalFilterFormButton}
                                    onClick={e=>handleFormCancel(e, formik.resetForm)}
                                >
                                    {translate.reset}
                                </Button>}
                            </>
                        }
                    </GridItem>
                </GridContainer>
            </form>
        )}
    </Formik>
    );
}

GlobalFilters.prototype = {
    data: PropTypes.object,
    editForm: PropTypes.bool,
    name: PropTypes.string,
    loadErrorText: PropTypes.string,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
}

export default withRouter(GlobalFilters);

