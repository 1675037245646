import {
    defaultFont,
    dangerColor,
    cardTitle
} from "../../assets/jss/material-dashboard-pro-react";


const styles = theme => ({
    ...defaultFont,
    root: {
        padding: theme.spacing(2),
    },
    cardTitle: {
        float: "left",
        padding: "10px 10px 10px 0px",
        lineHeight: "24px"
    },
    cardIconTitle: {
      ...cardTitle,
      marginTop: "15px",
      marginBottom: "0px"
    },
    textError: {
      color: dangerColor[0],
      textAlign: "center",
      padding: "30px 0",
      fontSize: "0.75rem",
      lineHeight: "1.66",
      fontWeight: "400"
    }
});

export default styles;
