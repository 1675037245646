import AuthApi from "../services/AuthApi";

// Constants
const initialState = {
    object: {}
}

// Types
const LOAD_TOOLTIPS = 'LOAD_TOOLTIPS'
const GET_TOOLTIPS = 'GET_TOOLTIPS'

// Reducers
export default function reducer(state = initialState, { type, payload }) {
    switch (type) {

    case LOAD_TOOLTIPS:
        return { ...state, ...payload }

    default:
        return state
    }
}

// Actions
export const loadToolTips = () => async (dispatch) => {
    try {
        const res = await AuthApi({
            method: "post",
            url: "/front_ws/call/",
            data: {
                id: null,
                name: "tooltips",
                session: "",
                args: {}
            },
            headers: {
                "Accept": "application/json"
            }
        });
        dispatch({
            type: LOAD_TOOLTIPS,
            payload: res.data.response.tooltips
        })
    } catch (error) {
        console.log(error);
    }
}

export const getToolTips = () => (dispatch) => {
    dispatch({
        type: GET_TOOLTIPS
    })
}