import {
  container,
  cardTitle,
  primaryColor,
  whiteColor,
  grayColor,
  blackColor,
  hexToRgb
} from "assets/jss/material-dashboard-pro-react.js";

const loginPageStyle = theme => ({
  container: {
    ...container,
    zIndex: "4",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "100px"
    }
  },
  cardTitle: {
    ...cardTitle,
    color: whiteColor
  },
  textCenter: {
    textAlign: "center"
  },
  justifyContentCenter: {
    justifyContent: "center !important"
  },
  customButtonClass: {
    "&,&:focus,&:hover": {
      color: whiteColor
    },
    marginLeft: "5px",
    marginRight: "5px"
  },
  inputAdornment: {
    marginRight: "18px"
  },
  inputAdornmentIcon: {
    fill: primaryColor[0],
    marginRight: "12px"
  },
  inputAdornmentIconButton: {
    fill: primaryColor[0]
  },
  cardHidden: {
    opacity: "0",
    transform: "translate3d(0, -60px, 0)"
  },
  cardHeader: {
    marginBottom: "20px"
  },
  cardBody: {
    padding: 0
  },
  cardBodyContent: {
    [theme.breakpoints.up("sm")]: {
      maxWidth: "388px",
      margin: "0 auto"
    }
  },
  cardFooter: {
    margin: "49px 0 0 0"
  },
  socialLine: {
    padding: "0.9375rem 0"
  },
  cardContainer: {
    borderRadius: 0,
    boxShadow: "none",
    margin: 0
  },
  cardHeaderForgot: {
    width: "100%",
    margin: "0 !important",
    background: "white !important",
    boxShadow: '5px 5px 10px rgba(0, 0, 0, .3)',
    padding: "10px 30px !important",
    borderRadius: "3px",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "388px",
      margin: "-50px auto 0 auto !important",
    }
  },
  cardFooter: {
    justifyContent: "center",
    flexDirection: "column"
  },
  cardBodyForgot: {
    paddingBottom: "0"
  },
  cardFooterForgot: {
    justifyContent: "center",
    flexDirection: "column",
    paddingTop: "0",
    paddingBottom: "30px"
  },
  logoHader: {
    height: "auto",
    maxWidth: "100%"
  },
  forgotTitle: {
    fontSize: "1.375rem",
    fontWeight: 700,
    color: grayColor[16],
    textAlign: "center"
  },
  forgotText: {
    fontSize: "1rem",
    fontWeight: 400,
    color: grayColor[16],
    textAlign: "center"
  },
  forgotButtonEnter: {
    marginLeft: "5px"
  },
  rememberText: {
    color: primaryColor[0],
    cursor: "pointer",
    marginTop: "20px",
    "&:hover": {
      color: grayColor[16]
    }
  },
  loginBody: {
    display: "flex",
    flexDirection: "column",
    transform: "translate3d(" + hexToRgb(blackColor) + ")",
    transition: "all 300ms linear",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row"
    }
  },
  loginForm: {
    maxHeight: "100%",
    boxSizing: "border-box",
    "-webkit-box-flex": 1,
    "-ms-flex": "1 1 100%",
    flex: "1 1 100%",
    maxWidth: "100%",
    padding: "5px 32px 32px 32px",
    backgroundColor: "#FFFFFF",
    [theme.breakpoints.up("sm")]: {
      "-webkit-box-flex": 1,
      "-ms-flex": "1 1 50%",
      flex: "1 1 50%",
      maxWidth: "50%",
    }
  },
  forgotPasswordContainer: {
    textAlign: "center"
  },
  btnForgotPassword: {
    display: "inline-block",
    background: "none",
    border: "none",
    cursor: "pointer",
    color: primaryColor[0]
  },
  loginAccount: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    maxHeight: "100%",
    boxSizing: "border-box",
    "-webkit-box-flex": 1,
    "-ms-flex": "1 1 100%",
    flex: "1 1 100%",
    maxWidth: "100%",
    padding: "32px",
    backgroundColor: "#FFFFFF",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    [theme.breakpoints.up("sm")]: {
      "-webkit-box-flex": 1,
      "-ms-flex": "1 1 50%",
      flex: "1 1 50%",
      maxWidth: "50%",
    }
  },
  accountHeader: {
    "& h2": {
      fontSize: "16px",
      color: "white",
      textAlign: "center",
      fontWeight: "500",
      lineHeight: "1.1",
      marginTop: "20px",
      marginBottom: "10px",
    },
    "& p": {
      fontSize: "12px",
      color: "white",
      textAlign: "center",
      fontWeight: "400",
      lineHeight: "1.1",
      marginBottom: "16px",
    }
  },
  socialMedia: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    "& > a": {
      margin: "0 7px",
      color: primaryColor[0],
      "&:hover": {
        color: grayColor[16]
      }
    }
  }
});

export default loginPageStyle;
