import API, { cognitoStorageId, cognitoStorageUser } from './api.js';
import AuthApi from './AuthApi.js';
import {Cookies} from 'react-cookie';

const AuthServices = {
    login: (email, password) => {
        const data = {
            id: 123,
            name: "login_coach",
            session: "",
            args: {
                email: email,
                password: password
            }
        }

        return AuthApi({
            method: "post",
            url: "/front_ws/call/",
            data: data,
            headers: {
                "Accept": "application/json"
            }
        });
    },
    signup: (args) => {
        const data = {
            id: null,
            name: "signup",
            session: "",
            args: args
        }

        return AuthApi({
            method: "post",
            url: "/front_ws/call/",
            data: data,
            headers: {
                "Accept": "application/json"
            }
        });
    },
    forgotPassword: (email) => {
        const data = {
            id: null,
            name: "forgot_password",
            session: "",
            args: {email}
        }

        return AuthApi({
            method: "post",
            url: "/front_ws/call/",
            data: data,
            headers: {
                "Accept": "application/json"
            }
        });
    },
    getMasters: (fields, exclude) => {
        fields = typeof fields !== 'undefined' ? fields : [];
        exclude = typeof exclude !== 'undefined' ? exclude : [];
        const data = {
            id: null,
            name: "get_masters",
            session: "",
            args:{"fields": fields, "exclude": exclude}
        };

        return AuthApi({
            method: "post",
            url: "/front_ws/call/",
            data: data,
            headers: {
                "Accept": "application/json"
            }
        });
    },
    logout: () => {
      AuthServices.deleteUser();
    },
    saveUser: (user) => {
        user.profile.avatar_url = AuthServices.buildAvatarUrl(user.profile.avatar);

        localStorage.setItem('username', user.profile.username);
        localStorage.setItem('user', JSON.stringify(user.profile));
        localStorage.setItem('accessToken', user.session);
        if(user.preferences) {
            localStorage.setItem('preferences', JSON.stringify(user.preferences));
        }
        
        AuthServices.saveCookieUser(user);

        return user.profile;
    },
    getUser: () => {
        const username = localStorage.getItem('username');
        const user = localStorage.getItem('user');
        const preferences = localStorage.getItem('preferences');

        if (user) {
            return {user: JSON.parse(user), preferences: JSON.parse(preferences)};
        }

        /* const cookies = new Cookies().getAll();

        if (cookies.username && username !== cookies.username) {
            AuthServices.saveUser(cookies);
        }

        if (cookies.username) {
            return cookies;
        } */

        return null;
    },
    deleteUser: () => {
        localStorage.removeItem('username');
        localStorage.removeItem('user');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('preferences');
        
        AuthServices.deleteCookieUser();
    },
    getCurrentToken: () => {
        return localStorage.getItem('accessToken');
    },
    getIEGToken: () => {
        return new Promise((resolve, reject) => {

            const accessToken = AuthServices.getCurrentToken();
            if (!accessToken) {
                resolve('');
            }

            AuthApi({
                method: "post",
                url: "/front_ws/call/",
                data: {
                    id: null,
                    name: "get_ieg_token",
                    session: "",
                    args:{}
                },
                headers: {
                    "Authorization": accessToken,
                    "Accept": "application/json"
                }
            }).then((res) => {
                let params = '';
                if (res.data.error !== true) {
                    params = '?iegt=' + res.data.response.iegt + '&uid=' + res.data.response.uid;
                } else {
                    console.error(res.data.error_msg + '\n' + res.data.stacktrace);
                }
                resolve(params);
            })

        })

        /* $http.post(API_CONFIG.Url, {
            id: null,
            session: '',
            name: 'get_ieg_token',
            args: {}
        }).success(function (data, status) {
            let params = '';
            if (data.error !== true)
                params = '?iegt=' + data.response.iegt + '&uid=' + data.response.uid;
            else
                console.error(data.error_msg + '\n' + data.stacktrace);

            deferred.resolve(params);
        }).error(function (data, status) {
            deferred.resolve('');
        }); */
    },
    buildAvatarUrl: (avatar) => {
        console.log(process.env.REACT_APP_ET_API + avatar)
        if (avatar)
            return process.env.REACT_APP_ET_API + avatar;
        else
            return require('assets/img/faces/default.jpg');
    },
    getCookies: () => {
        return new Cookies().getAll();
    },
    getCookieAccessToken: () => {
        const tkn = new Cookies().get('accessToken');
        return tkn;
    },
    saveCookieUser: (user) => {
        const cookies = new Cookies();
        console.log('no avatar_url');
        cookies.set('accessToken', user.session, {path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN})
        cookies.set('full_name', user.profile.full_name, {path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN})
        cookies.set('first_name', user.profile.first_name, {path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN})
        cookies.set('last_name', user.profile.last_name, {path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN})
        cookies.set('coach_license_type', user.profile.coach_license_type, {path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN})
        cookies.set('username', user.profile.username, {path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN})
        cookies.set('coach_id', user.profile.coach_id, {path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN})
        cookies.set('user_id', user.profile.id, {path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN})
        cookies.set('gender', user.profile.gender, {path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN})
        cookies.set('avatar', user.profile.avatar, {path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN})
        cookies.set('is_head_team', user.profile.is_head_team, {path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN})
        cookies.set('ieg_member', user.profile.ieg_member, {path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN})
        cookies.set('team_name', user.profile.team_name, {path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN})
        cookies.set('team_id', user.profile.team_id, {path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN})
        cookies.set('is_athlete', user.profile.is_athlete, {path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN})
        cookies.set('is_coach', user.profile.is_coach, {path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN})
        cookies.set('is_student', user.profile.is_student, {path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN})
    },
    getCookieUser: () => {
        const cookies = new Cookies().getAll();
        const user = cookies.username ? {} : null;
        return user;
    },
    deleteCookieUser: () => {
        const cookies = new Cookies();
        cookies.remove('full_name', {domain: process.env.REACT_APP_COOKIE_DOMAIN, path: '/'});
        cookies.remove('accessToken', {domain: process.env.REACT_APP_COOKIE_DOMAIN, path: '/'});
        cookies.remove('first_name', {domain: process.env.REACT_APP_COOKIE_DOMAIN, path: '/'});
        cookies.remove('last_name', {domain: process.env.REACT_APP_COOKIE_DOMAIN, path: '/'});
        cookies.remove('coach_license_type', {domain: process.env.REACT_APP_COOKIE_DOMAIN, path: '/'});
        cookies.remove('username', {domain: process.env.REACT_APP_COOKIE_DOMAIN, path: '/'});
        cookies.remove('coach_id', {domain: process.env.REACT_APP_COOKIE_DOMAIN, path: '/'});
        cookies.remove('user_id', {domain: process.env.REACT_APP_COOKIE_DOMAIN, path: '/'});
        cookies.remove('gender', {domain: process.env.REACT_APP_COOKIE_DOMAIN, path: '/'});
        cookies.remove('avatar', {domain: process.env.REACT_APP_COOKIE_DOMAIN, path: '/'});
        cookies.remove('avatar_url', {domain: process.env.REACT_APP_COOKIE_DOMAIN, path: '/'});
        cookies.remove('is_head_team', {domain: process.env.REACT_APP_COOKIE_DOMAIN, path: '/'});
        cookies.remove('ieg_member', {domain: process.env.REACT_APP_COOKIE_DOMAIN, path: '/'});
        cookies.remove('team_name', {domain: process.env.REACT_APP_COOKIE_DOMAIN, path: '/'});
        cookies.remove('team_id', {domain: process.env.REACT_APP_COOKIE_DOMAIN, path: '/'});
        cookies.remove('is_athlete', {domain: process.env.REACT_APP_COOKIE_DOMAIN, path: '/'});
        cookies.remove('is_coach', {domain: process.env.REACT_APP_COOKIE_DOMAIN, path: '/'});
        cookies.remove('is_student', {domain: process.env.REACT_APP_COOKIE_DOMAIN, path: '/'});
    }
};

export default AuthServices;
