import * as yup from 'yup';
import { parse, isDate } from "date-fns";
import moment from 'moment';

function parseDateString(value, originalValue) {
    let parsedDate = '';
    if(isDate(originalValue)) {
        parsedDate = originalValue;
    } else if(moment(originalValue, "DD/MM/YYYY").isValid()) {
        parsedDate = moment(originalValue, "DD/MM/YYYY").toDate();
    } else if(moment(originalValue, "MM/DD/YYYY").isValid()) {
        parsedDate = moment(originalValue, "MM/DD//YYYY").toDate();
    } else {
        parsedDate = parse(originalValue, "yyyy-MM-dd", new Date());
    }
    return parsedDate;
}

/** Adding just additional methods here */

yup.addMethod(yup.date, "string-test", function(...args){
    return this.test('match-string-test', args[1], function (value, el) {
        if (value === undefined) { return true; }
        const regex = new RegExp(args[2], "gi");
        const isValid = regex.test(el.originalValue);
        return isValid;
    });
});

yup.addMethod(yup.string, "URL", function(...args) {
    return this.url(...args);
});

const validator = function (message) {
    return this.test('is-string-boolean', message, function (value) {
        if (value.trim() === "") {
            return true;
        }

        if (['Y', 'N'].indexOf(value) !== -1) {
            return true;
        } else {
            return false;
        }
    });
};

yup.addMethod(yup.string, "stringBoolean", validator);
yup.addMethod(yup.string, "StringBoolean", validator);

export function createYupSchema(schema, config) {
    const { accessor: field, validationType, validations = [] } = config;
    if (!yup[validationType]) {
        return schema;
    }
    let validator = yup[validationType]();
    validations.forEach((validation) => {
        const { params, type } = validation;
        if (!validator[type]) {
            return;
        }
        validator = validationType === 'date' ? validator.transform(parseDateString)[type](...params) : validator[type](...params);
    });
    if (field.indexOf('.') !== -1) {
        // nested fields are not covered yet
    } else {
        schema[field] = validator;
    }

    return schema;
}

export const getYupSchemaFromMetaData = (
    metadata,
    additionalValidations,
    forceRemove
) => {
    const yepSchema = metadata.reduce(createYupSchema, {});
    const mergedSchema = {
        ...yepSchema,
        ...additionalValidations,
    };

    forceRemove.forEach((field) => {
        delete mergedSchema[field];
    });

    const validateSchema = yup.object().shape(mergedSchema);

    return validateSchema;
};
