import React from 'react'
import {withRouter} from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-dashboard-pro-react/views/signupPageStyle.js";

const useStyles = makeStyles(styles);

const logo = require("assets/img/logos/LogoEnduranceTool_signup.png");
const logo_footer = require("assets/img/logos/LogoEnduranceTool_signup.png");

const SignUpPage = (props) => {

    const classes = useStyles();

    const onClickLogin = () => {
        props.history.push('/auth/login')
    }

    return (
        <div className={classes.signUpContainer}>
            <GridContainer className={classes.mainContainer} spacing={0}>
                <GridItem xs={12} className={classes.header}>
                    <img src={logo} className={classes.logo} />
                </GridItem>
                <GridItem xs={12} className={classes.content}>
                    <div className={classes.contentContainerFinished}>
                        <h2 className={classes.h2}>¡Cuenta activada con éxito!</h2>
                        <p className={classes.text}>Ya puedes ingresar usando tu email y contraseña.</p>
                        <div className={classes.iconCheck}><i class="material-icons">check</i></div>
                        <button className={classes.buttonLogin} onClick={onClickLogin}>INGRESAR</button>
                    </div>
                </GridItem>
                <GridItem xs={12} className={classes.footer}>
                    <div className={classes.footerTop}>
                        <div className={classes.logoMenuFooter}>
                            <div className={classes.logoFooter}>
                                <img src={logo_footer} className={classes.logoImage} />
                            </div>
                            <div className={classes.menuFooter}>
                                <a href="https://www.endurancegroup.org/index.php/quienes-somos" className={classes.menuLink}>Quienes Somos</a>
                                <a href="https://www.endurancegroup.org/index.php/contacto" className={classes.menuLink}>Contacto</a>
                                <a href="https://www.endurancegroup.org/index.php/politicas-de-privacidad" className={classes.menuLink}>Privacidad</a>
                                <a href="https://www.endurancegroup.org/index.php/terminos-y-condiciones" className={classes.menuLink}>Términos y Condiciones</a>
                            </div>
                        </div>
                        <div className={classes.socialMedia}>
                            <a href="https://www.facebook.com/InternationalEnduranceGroup/" className={classes.socialIcon} alt="Facebook">
                                <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="facebook">
                                    <path d="M11,10h2.6l0.4-3H11V5.3c0-0.9,0.2-1.5,1.5-1.5H14V1.1c-0.3,0-1-0.1-2.1-0.1C9.6,1,8,2.4,8,5v2H5.5v3H8v8h3V10z"></path>
                                </svg>
                            </a>
                            <a href="https://twitter.com/IEnduranceGroup" className={classes.socialIcon} alt="Twitter">
                                <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="twitter">
                                    <path d="M19,4.74 C18.339,5.029 17.626,5.229 16.881,5.32 C17.644,4.86 18.227,4.139 18.503,3.28 C17.79,3.7 17.001,4.009 16.159,4.17 C15.485,3.45 14.526,3 13.464,3 C11.423,3 9.771,4.66 9.771,6.7 C9.771,6.99 9.804,7.269 9.868,7.539 C6.795,7.38 4.076,5.919 2.254,3.679 C1.936,4.219 1.754,4.86 1.754,5.539 C1.754,6.82 2.405,7.95 3.397,8.61 C2.79,8.589 2.22,8.429 1.723,8.149 L1.723,8.189 C1.723,9.978 2.997,11.478 4.686,11.82 C4.376,11.899 4.049,11.939 3.713,11.939 C3.475,11.939 3.245,11.919 3.018,11.88 C3.49,13.349 4.852,14.419 6.469,14.449 C5.205,15.429 3.612,16.019 1.882,16.019 C1.583,16.019 1.29,16.009 1,15.969 C2.635,17.019 4.576,17.629 6.662,17.629 C13.454,17.629 17.17,12 17.17,7.129 C17.17,6.969 17.166,6.809 17.157,6.649 C17.879,6.129 18.504,5.478 19,4.74"></path>
                                </svg>
                            </a>
                            <a href="https://www.youtube.com/channel/UC6l-ZmcnY_eNW4_AfyH14jQ" className={classes.socialIcon} alt="Youtube">
                                <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="youtube">
                                    <path d="M15,4.1c1,0.1,2.3,0,3,0.8c0.8,0.8,0.9,2.1,0.9,3.1C19,9.2,19,10.9,19,12c-0.1,1.1,0,2.4-0.5,3.4c-0.5,1.1-1.4,1.5-2.5,1.6 c-1.2,0.1-8.6,0.1-11,0c-1.1-0.1-2.4-0.1-3.2-1c-0.7-0.8-0.7-2-0.8-3C1,11.8,1,10.1,1,8.9c0-1.1,0-2.4,0.5-3.4C2,4.5,3,4.3,4.1,4.2 C5.3,4.1,12.6,4,15,4.1z M8,7.5v6l5.5-3L8,7.5z"></path>
                                </svg>
                            </a>
                            <a href="https://www.instagram.com/internationalendurancegroup" className={classes.socialIcon} alt="Instagram">
                                <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="instagram">
                                    <path d="M13.55,1H6.46C3.45,1,1,3.44,1,6.44v7.12c0,3,2.45,5.44,5.46,5.44h7.08c3.02,0,5.46-2.44,5.46-5.44V6.44 C19.01,3.44,16.56,1,13.55,1z M17.5,14c0,1.93-1.57,3.5-3.5,3.5H6c-1.93,0-3.5-1.57-3.5-3.5V6c0-1.93,1.57-3.5,3.5-3.5h8 c1.93,0,3.5,1.57,3.5,3.5V14z"></path><circle cx="14.87" cy="5.26" r="1.09"></circle><path d="M10.03,5.45c-2.55,0-4.63,2.06-4.63,4.6c0,2.55,2.07,4.61,4.63,4.61c2.56,0,4.63-2.061,4.63-4.61 C14.65,7.51,12.58,5.45,10.03,5.45L10.03,5.45L10.03,5.45z M10.08,13c-1.66,0-3-1.34-3-2.99c0-1.65,1.34-2.99,3-2.99s3,1.34,3,2.99 C13.08,11.66,11.74,13,10.08,13L10.08,13L10.08,13z"></path>
                                </svg>
                            </a>
                            <a href="https://www.linkedin.com/company/iewg/" className={classes.socialIcon} alt="Linkedin">
                                <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="linkedin">
                                    <path d="M5.77,17.89 L5.77,7.17 L2.21,7.17 L2.21,17.89 L5.77,17.89 L5.77,17.89 Z M3.99,5.71 C5.23,5.71 6.01,4.89 6.01,3.86 C5.99,2.8 5.24,2 4.02,2 C2.8,2 2,2.8 2,3.85 C2,4.88 2.77,5.7 3.97,5.7 L3.99,5.7 L3.99,5.71 L3.99,5.71 Z"></path><path d="M7.75,17.89 L11.31,17.89 L11.31,11.9 C11.31,11.58 11.33,11.26 11.43,11.03 C11.69,10.39 12.27,9.73 13.26,9.73 C14.55,9.73 15.06,10.71 15.06,12.15 L15.06,17.89 L18.62,17.89 L18.62,11.74 C18.62,8.45 16.86,6.92 14.52,6.92 C12.6,6.92 11.75,7.99 11.28,8.73 L11.3,8.73 L11.3,7.17 L7.75,7.17 C7.79,8.17 7.75,17.89 7.75,17.89 L7.75,17.89 L7.75,17.89 Z"></path>
                                </svg>
                            </a>
                        </div>
                    </div>
                    <div className={classes.footerBottom}>
                        <div className={classes.copyright}>
                            <p><strong>© 2019 | Todos los derechos reservados</strong><br />
                                No está permitida la reproducción total ni parcial de este sitio web, su tratamiento informático, la transmisión de ninguna forma o por cualquier medio, ya sea electrónico, mecánico, u otros métodos, sin el permiso previo y escrito de los titulares de los derechos de autor.
                            </p>
                        </div>
                    </div>
                </GridItem>
            </GridContainer>
        </div>
    )
}

export default withRouter(SignUpPage)
