import {
    primaryColor
} from "../../assets/jss/material-dashboard-pro-react.js";

import customInputStyle from "assets/jss/material-dashboard-pro-react/components/customInputStyle.js";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";

const styles = {
    root: {
        color: primaryColor[0]
    },
    ...validationFormsStyle,
    ...customSelectStyle,
    ...customInputStyle,
    checkRootSmall: {
        padding: "6px 14px"
    },
    textCenter: {
        textAlign: "center"
    },
    formButtonWithLoader: {
        position: "relative",
        "& div[class^='makeStyles-rowCenteredLoader']": {
            position: "absolute",
            zIndex: "10",
            padding: ".3125rem 1px",
            height: "100%",
            backgroundColor: 'rgba(255,255,255,.5)',
            "& svg": {
                verticalAlign: 'middle'
            }
        }
    }
};

export default styles;
