import React from 'react';
import axios from 'axios';
import { get_language } from "../locale";
import TokenStorage from './TokenStorage';
import awsconfig from '../aws-exports';
import AuthServices from "services/AuthServices";

const username = localStorage.getItem('username');
const cognitoStorageId = `CognitoIdentityServiceProvider.${awsconfig.userPoolWebClientId}`;
const cognitoStorageUser = `${cognitoStorageId}.${username}`;

const cancelToken = axios.CancelToken.source();
const refreshTokenUrl = process.env.REACT_APP_API_URL + '/refresh_token/';
const getAccessTokenKey = `${cognitoStorageUser}.accessToken`;
const getRefreshTokenKey = `${cognitoStorageUser}.refreshToken`;

const axiosApiInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        "Accept-Language": get_language
    },
    /*validateStatus: (status) => {
        if(status === 401){
            window.location = '/auth/login-page';
            return false;
        }
        return status >= 200 && status < 300; // default
    }*/
});

// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
  async config => {
    const accessToken = localStorage.getItem('accessToken');
    config.headers = {
      'Authorization': `Bearer ${accessToken}`,
      // 'Accept': 'application/json',
      // 'Content-Type': 'application/x-www-form-urlencoded'
      'Content-Type': 'application/json'
    }
    return config;
  },
  error => {
    Promise.reject(error)
});

// Response interceptor for API calls
/* axiosApiInstance.interceptors.response.use((response) => {
  return response
}, async function (error) {
  const originalRequest = error.config;
  // Return any error which is not due to authentication back to the calling service
  if (error.response.status !== 401) {
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }

  // Logout user if token refresh didn't work or user is disabled
  console.log(error.config);
  console.log(error.response.message);
  if (error.config.url === '/refresh_token' || error.response.message === 'Account is disabled.') {
    TokenStorage.clear();
    window.location = "/auth/login"

    return new Promise((resolve, reject) => {
      reject(error);
    });
  }

  // Try request again with new token
  return TokenStorage.getNewToken()
    .then((token) => {

      // New request with new token
      const config = error.config;
      config.headers['Authorization'] = `Bearer ${token}`;

      return new Promise((resolve, reject) => {
        axios.request(config).then(response => {
          resolve(response);
        }).catch((error) => {
          reject(error);
        })
      });

    })
    .catch((error) => {
      Promise.reject(error);
    });
}); */

export default axiosApiInstance;

export {
  cancelToken,
  cognitoStorageId,
  cognitoStorageUser,
  getAccessTokenKey,
  getRefreshTokenKey,
  refreshTokenUrl
};