import LocalizedStrings from 'react-localization';

const strings = new LocalizedStrings({
    en:{
        "login": "Log in",
        "logout": "Log out",
        "register": "Register",
        "profile": "Profile",
        "settings": "Settings",
        "email": "E-mail",
        "password": "Password",
        "login_button_text": "Let´s go",
        "incorrect_username_or_password": "Incorrect username or password.",
        "incorrect_email": "Incorrect e-mail.",
        "send_code": "Send code",
        "forgot_your_password": "Forgot your password?",
        "reset_your_password": "Reset your password",
        "verification_code": "Verification code",
        "new_password": "New password",
        "repeat_password": "Repeat password",
        "passwords_do_not_match": "Passwords do not match",
        "change_password": "Change password",
        "send": "Send",
        "all_fields_required": "All fields required",
        "list": "List",
        "add": "Add",
        "edit": "Edit",
        "delete": "Delete",
        "save": "Save",
        "cancel": "Cancel",
        "back": "Back",
        "back_to_et": "Back to E-Tool",
        "autogenerate_table_error": "The table could not be created.{0}Server error.",
        "autogenerate_form_error": "The form could not be created.{0}Server error.",
        "required_fields": "Required fields",
        "upload_file": "Upload file",
        "upload_image": "Upload image",
        "select_file": "Select file",
        "select_image": "Select image",
        "yes": "Yes",
        "no": "No",
        "see_current_image": "See current image",
        "loading": "Loading",
        "confirm_delete_title": "Delete item",
        "confirm_delete_text": "Are you sure deleting item \"{0}\"?",
        "empty_table": "Empty table",
        "search_x_records": "Search {0} records",
        "x_records": "{0} records",
        "search": "Search",
        "actions": "Actions",
        "previous": "Previous",
        "next": "Next",
        "rows": "Rows",
        "page": "Page",
        "create_payment_link": "Create payment link",
        "reset": "Reset",
        "register": "Register",
        "first_name": "First name",
        "first_name_desc": "Has to be the same of the identity card",
        "last_name": "Last name",
        "email_desc": "ie: mail@gmail.com",
        "nationality": "Nationality",
        "gender": "Gender",
        "female": "Female",
        "male": "Male",
        "password_desc": "Choose one at least with 8 characters, 1 uppercase and 1 number.",
        "confirm_password": "Confirm password",
        "confirm_password_desc": "Repeat password",
        "have_an_account": "Have an account?",
        "enter": "Log in",
        "Redirecting": "Redirecting"
    },
    es: {
        "login": "Iniciar sesión",
        "logout": "Cerrar sesión",
        "register": "Crear cuenta",
        "profile": "Perfil",
        "settings": "Configuraciones",
        "email": "E-mail",
        "password": "Contraseña",
        "login_button_text": "Ingresar",
        "incorrect_username_or_password": "Nombre de usuario o contraseña incorrecta.",
        "incorrect_email": "El e-mail es incorrecto.",
        "send_code": "Enviar código",
        "forgot_your_password": "¿Olvidó su contraseña?",
        "reset_your_password": "Resetear la contraseña",
        "verification_code": "Código de verificación",
        "new_password": "Nueva contraseña",
        "repeat_password": "Repita contraseña",
        "passwords_do_not_match": "Las contaseñas no coinciden",
        "change_password": "Cambiar contraseña",
        "send": "Enviar",
        "all_fields_required": "Todos los campos son requeridos",
        "list": "Listado",
        "add": "Agregar",
        "edit": "Editar",
        "delete": "Borrar",
        "save": "Guardar",
        "cancel": "Cancelar",
        "back": "Volver",
        "back_to_et": "Volver a E-Tool",
        "autogenerate_table_error": "No se puede generar la tabla.{0}Error en el servidor.",
        "autogenerate_form_error": "No se puede generar el formulario.{0}Error en el servidor.",
        "required_fields": "Datos requeridos",
        "upload_file": "Cargar archivo",
        "upload_image": "Cargar imagen",
        "select_file": "Selecciona un archivo",
        "select_image": "Selecciona una imagen",
        "yes": "Si",
        "no": "No",
        "see_current_image": "Ver imagen actual",
        "loading": "Cargando",
        "confirm_delete_title": "Borrar registro",
        "confirm_delete_text": "¿Seguro que quieres de borrar el registro \"{0}\"?",
        "empty_table": "No hay registros",
        "search_x_records": "Buscar {0} registros",
        "x_records": "{0} registros",
        "search": "Buscar",
        "actions": "Acciones",
        "previous": "Anterior",
        "next": "Siguiente",
        "rows": "Filas",
        "page": "Página",
        "close": "Cerrar",
        "create_payment_link": "Crear link de pago",
        "reset": "Resetear",
        "register": "Crear Cuenta",
        "first_name": "Nombre",
        "first_name_desc": "Como aparece en el documento de identidad",
        "last_name": "Apellido",
        "email_desc": "Ej. mail@gmail.com",
        "nationality": "Nacionalidad",
        "gender": "Género",
        "female": "Femenino",
        "male": "Masculino",
        "password_desc": "Para mayor seguridad 8 caracteres, 1 mayúscula y 1 número.",
        "confirm_password": "Confirmar contraseña",
        "confirm_password_desc": "Repite la contraseña elegida",
        "have_an_account": "¿Ya tienes cuenta?",
        "enter": "Ingresar",
        "Redirecting": "Redireccionando"
    }
});

const getBrowserLanguage = () => {
    let lang = null;
    if (navigator.languages && navigator.languages.length) {
        lang = navigator.languages[0];
    } else {
        lang = navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en';
    }
    return lang.match(/(\w+)/)[0];
}

const get_language = strings.getLanguage();
const set_language = lang=>{strings.setLanguage(lang)}
const interface_lang = strings.getInterfaceLanguage()
const browser_language = getBrowserLanguage();
const set_browser_language = ()=>{set_language(browser_language)};

set_language(browser_language);
export {
    strings,
    get_language,
    set_language,
    interface_lang,
    browser_language,
    set_browser_language
}
