import React, { useState, useCallback } from 'react';

const defaultOptions = {
  message: "",
  messageLength: 256,
  status: "",
  place: "tr",
  color: "info", // "info"|"success"|"warning"|"danger"|"primary"|"rose"
  icon: "AddAlert",
  autoHidden: true,
  delayHidden: 6000
}

export const MainNotificationContext = React.createContext({
  notification: defaultOptions,
  addInfo: () => {},
  removeInfo: () => {},
  addError: () => {},
  removeError: () => {},
  addSuccess: () => {},
  removeSuccess: () => {},
  addWarning: () => {},
  removeWarning: () => {},
});

export default function MainNotificationProvider({ children }) {
  const [notification, setNotification] = useState(null);

  const getStatus = (message, status) => {
    if (status === null && /status code (\d{3})/i.test(message.toString())){
      const status = message.toString().match(/status code (\d{3})/i);
      return status[1];
    }
    return status;
  }

  const contextValue = {
    notification,
    addInfo: useCallback((message, status, config) => setNotification({ ...defaultOptions, message, status: getStatus(message, status), ...config }), []),
    removeInfo: useCallback(() => setNotification(null), []),
    addError: useCallback((message, status, config) => setNotification({ ...defaultOptions, message, status: getStatus(message, status), ...config, color: "danger" }), []),
    removeError: useCallback(() => setNotification(null), []),
    addSuccess: useCallback((message, status, config) => setNotification({ ...defaultOptions, message, status: getStatus(message, status), ...config, color: "success" }), []),
    removeSuccess: useCallback(() => setNotification(null), []),
    addWarning: useCallback((message, status, config) => setNotification({ ...defaultOptions, message, status: getStatus(message, status), ...config, color: "warning" }), []),
    removeWarning: useCallback(() => setNotification(null), []),
  };

  return (
    <MainNotificationContext.Provider value={contextValue}>
      {children}
    </MainNotificationContext.Provider>
  );
}