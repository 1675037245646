import React from "react";
import cx from "classnames";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import routes from "routes.js";

import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";

import MainNotificationProvider from "../providers/MainNotificationProvider";
import MainNotification from "../components/MainNotification";
import AuthServices from "services/AuthServices";

var ps;
var sidebarWrapperDOM;

const useStyles = makeStyles(styles);

export default function Dashboard(props) {
  const { ...rest } = props;
  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [miniActive, setMiniActive] = React.useState(false);
  const image = '';
  const color = "orange";
  const bgColor = "orange";
  const logo = require("assets/img/logos/EnduranceTool_navOpen.png");
  // styles
  const classes = useStyles();

  const showSidebar = (routes) => {
    let show = true;
    const adminPages = routes.filter(item=>(item.layout==='/admin' || item.layout==='/et' || item.layout==='/et-academy'));
    const fullPages = adminPages.filter(item=>item.sidebar===false);
    fullPages.forEach(item=>{
      if(window.location.pathname === `${item.layout}${item.path}`){
        show = false
      }
    })
    return show
  }

  const mainPanelClasses =
    cx({
      [classes.mainPanel]: showSidebar(routes),
      [classes.mainFullPanel]: !showSidebar(routes)
    }) +
    " " +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf("Win") > -1
    });
  const mainFullPanelClasses =
    classes.mainFullPanel +
    " " +
    cx({
      [classes.mainFullPanelSidebarMini]: miniActive,
      [classes.mainFullPanelWithPerfectScrollbar]:
        navigator.platform.indexOf("Win") > -1
    });
  // ref for main panel div
  const mainPanel = React.createRef();
  const [isAuthorized, setIsAuthorized] = React.useState(false);

  React.useEffect(() => {
    const currentUser = AuthServices.getUser();
    if(!currentUser) {
      // window.location.replace(process.env.REACT_APP_ET_URL);
      props.history.push('/auth/login')
    } else {
      setIsAuthorized(true);
    }
  }, [])
  sidebarWrapperDOM = document.querySelector('#sidebarWrapperAdmin');
  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      var cont = mainPanel.current
      if(!cont) cont = sidebarWrapperDOM;
      if(cont) {
        ps = new PerfectScrollbar(cont, {
          suppressScrollX: true,
          suppressScrollY: false
        });
      }
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);

    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1 && ps) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  });
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    // return window.location.pathname !== "/et/home";
    return !window.location.pathname.match(/^\/(et|uni)\/?.*?/)
  };
  const getActiveRoute = routes => {
    let activeRoute = "Backoffice";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          document.title = "ET | " + routes[i].name;
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin" || prop.layout === "/et" || prop.layout === "/et-academy") {
        return (
          <Route
            path={prop.layout + prop.path}
            render={props => {
                // pass the sub-routes down to keep nesting
                return <prop.component {...props} routes={prop.routes} config={prop} />
            }}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  return (
    isAuthorized && <div className={classes.wrapper}>
      {getRoute() ? (<>
      {showSidebar(routes) && <Sidebar
        routes={routes}
        logoText={"Mi Negocio"}
        logo={logo}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        bgColor={bgColor}
        miniActive={miniActive}
        {...rest}
      />}
      <div id="sidebarWrapperAdmin" className={mainPanelClasses} ref={mainPanel}>
        <AdminNavbar
          sidebarToggleButton={showSidebar(routes)}
          miniActive={miniActive}
          brandText={getActiveRoute(routes)}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        <MainNotificationProvider>
          <div className={classes.content}>
            <div className={classes.container}>

              <Switch>
                {getRoutes(routes)}
                <Redirect from="/admin" to="/auth/login" />
              </Switch>
            </div>
          </div>
          <Footer fluid />
          <MainNotification />
        </MainNotificationProvider>
      </div>
      </>) : (<>
      <div id="sidebarWrapperAdmin" className={mainFullPanelClasses} ref={mainPanel}>
          <div className={classes.iframe}>
              <Switch>
                {getRoutes(routes)}
                <Redirect from="/admin" to="/auth/login" />
              </Switch>
            </div>
      </div>
      </>)}
    </div>
  );
}
