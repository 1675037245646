import React from "react";
import {strings as translate} from "locale";

// @material-ui/core components
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "../Grid/GridContainer.js";
import GridItem from "../Grid/GridItem.js";

import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";

const newStyles = {
    ...styles,
    formControlMargins: {
        margin: "3px 0 !important"
    },
    gridContainer: {
        justifyContent: "center"
    }
};

const useStyles = makeStyles(newStyles);

const Pagination = ( {previousPage,
                         canPreviousPage,
                         pageSelect,
                         gotoPage,
                         handlePageSelect,
                         pageSelectData,
                         numberOfRows,
                         pageIndex,
                         setPageSize,
                         setNumberOfRows,
                         numberOfRowsData,
                         nextPage,
                         canNextPage} ) => {
    const classes = useStyles();
    pageSelect = pageSelect ? pageSelect : 0;
    
    return (<div className="-pagination">
        <div className="-previous">
            <button
                type="button"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
                className="-btn"
            >
                {translate.previous}
            </button>
        </div>
        <div className="-center">
            <GridContainer className={classes.gridContainer}>
                <GridItem xs={12} sm={6} md={4}>
                    <FormControl
                        fullWidth
                        className={
                            classes.selectFormControl +
                            " " +
                            classes.formControlMargins
                        }
                    >
                        <Select
                            MenuProps={{
                                className: classes.selectMenu
                            }}
                            classes={{
                                select: classes.select
                            }}
                            value={pageIndex}
                            onChange={event => {
                                gotoPage(event.target.value);
                                handlePageSelect(event.target.value);
                            }}
                            inputProps={{
                                name: "pageSelect",
                                id: "page-select"
                            }}
                        >
                            {pageSelectData.map((prop, key) => {
                                return (
                                    <MenuItem
                                        key={key}
                                        classes={{
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelected
                                        }}
                                        value={key}
                                    >
                                        {translate.page} {key + 1}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                    <FormControl
                        fullWidth
                        className={
                            classes.selectFormControl +
                            " " +
                            classes.formControlMargins
                        }
                    >
                        <Select
                            MenuProps={{
                                className: classes.selectMenu
                            }}
                            classes={{
                                select: classes.select
                            }}
                            value={numberOfRows}
                            onChange={event => {
                                setPageSize(event.target.value);
                                setNumberOfRows(event.target.value);
                            }}
                            inputProps={{
                                name: "numberOfRows",
                                id: "number-of-rows"
                            }}
                        >
                            {numberOfRowsData.map(prop => {
                                return (
                                    <MenuItem
                                        key={prop}
                                        classes={{
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelected
                                        }}
                                        value={prop}
                                    >
                                        {prop} {translate.rows}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </GridItem>
            </GridContainer>
        </div>
        <div className="-next">
            <button
                type="button"
                onClick={() => nextPage()}
                disabled={!canNextPage}
                className="-btn"
            >
                {translate.next}
            </button>
        </div>
    </div>)
}

export default Pagination;
