import React from 'react';

// @material-ui/icons
import AddAlert from "@material-ui/icons/AddAlert";
// core components
import Snackbars from "components/Snackbar/Snackbar.js";

import useMainNotification from '../../hooks/useMainNotification';

function MainNotification() {
  const { notification, removeError } = useMainNotification();

  React.useEffect(() => {
    let timer = null;
    if(notification && notification.message) {
        if(notification.autoHidden) {
            timer = setTimeout(()=>{
              removeError()
            }, notification.delayHidden);
        }
    }
    return () => {
      clearTimeout(timer);
    }
  }, [notification, removeError]);

  const getIcon = (nameIcon) => {
    switch(nameIcon) {
        case "AddAlert":
            return AddAlert;
        default:
            return AddAlert;
    }
  }

  return (<>
      {notification && <Snackbars
          place={notification ? notification.place : 'tl'}
          color={notification ? notification.color : 'info'}
          icon={getIcon(notification ? notification.icon : '')}
          message={(notification && notification.message) ? <p>{notification.message.toString().substr(0, notification.messageLength || 256)}</p> : ''}
          open={(!!notification)}
          closeNotification={removeError}
          close
      />}
    
  </>);
}

export default MainNotification;