import axios from 'axios';
import { get_language } from "../locale";

const cancelToken = axios.CancelToken.source();

const axiosApiInstance = axios.create({
    baseURL: process.env.REACT_APP_ET_API,
    headers: {
        'Content-Type': 'application/json',
        "Accept-Language": get_language
    }
});

export default axiosApiInstance;

export {
  cancelToken
};