import React from 'react';
import { withRouter } from 'react-router-dom';
import { trackPromise } from 'react-promise-tracker';
import {strings as translate} from "../../locale";

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// core components
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardIcon from "../../components/Card/CardIcon";
import CardBody from "../../components/Card/CardBody";
import LoadingIndicator from "components/Loader/LoadingIndicator";
import Form from "../../components/CustomForms/Form";
import Button from "../../components/CustomButtons/Button";
import useMainNotification from '../../hooks/useMainNotification';

// services
import MainServices from "../../services/MainServices";

// contexts
import {GenericPageContext} from "../../providers/GenericPageProvider";

// styles
import styles from './styles';

const useStyles = makeStyles(styles);

const EditTeam = (props) => {
    const config = React.useContext(GenericPageContext)
    const model = config.model;
    const classes = useStyles();
    const [data, setData] = React.useState(() => []);
    const [loaded, setLoaded] = React.useState(false);
    const [columns, setColumns] = React.useState(2);
    const { id } = props;
    const { addError } = useMainNotification();

    React.useEffect(() => {
        async function loadData() {
            try {
                const res = await MainServices.getItem(model, id);
                if(!res.data.error) {
                    let newData = res.data.data;
                    if(res.data.config && res.data.config.form_layout_column) {
                      setColumns(res.data.config.form_layout_column)
                    }
                    newData.metadata = res.data.metadata;
                    setData(newData);
                }
            } catch (e) {
              if(e.message) {
                addError(e.message, null, {messageLength:null});
              } else {
                console.log(e);
              }
            }
        }
        trackPromise(loadData())
          .then(res => {
            setLoaded(true)
          });

        return () => { MainServices.cancel(); }
    }, [addError, model, id]);

    return (
      <>
        <LoadingIndicator
          type="TailSpin"
          color="#2e82ef"
          height={50}
          width={50}
        />

        <GridContainer>
          <GridItem xs={12}>
            <Button color="info" onClick={() => props.history.goBack()}>
              &lt; {translate.back}
            </Button>
          </GridItem>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="primary" icon>
                <CardIcon color="primary">{translate.edit}</CardIcon>
              </CardHeader>
              <CardBody>
                <div className={classes.root}>
                  {loaded && (
                    <Form
                      name={model}
                      data={data}
                      editForm={true}
                      columns={columns}
                    />
                  )}
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
}

export default withRouter(EditTeam);
