import React from "react";
import AuthApi from './AuthApi.js';
import AuthServices from './AuthServices';
import _ from "lodash";

const CoachServices = {
    updatePreferences: async (token) => {
        const res = await CoachServices.getDefaultPreferences(token)

        if (res.data.error === true) {
            return res.data;
        }
        
        if (_.isString(res.data.response))
            res.data.response = JSON.parse(res.data.response);

        return res.data.response;
    },
    getDefaultPreferences: (token) => {
        if (typeof(token)==='undefined') token = '';

        const data = {
            id: null,
            name: "get_coach_default_preferences",
            session: null,
            args: {}
        }

        return AuthApi({
            method: "post",
            url: "/front_ws/call/",
            data: data,
            headers: {
                "Accept": "application/json",
                "Authorization": token
            }
        });
    },
    getUpcomingActivities: () => {
        const data = {
            id: null,
            name: "get_upcoming_activities",
            session: null,
            args: {}
        }

        return AuthApi({
            method: "post",
            url: "/front_ws/call/",
            data: data,
            headers: {
                "Accept": "application/json",
                "Authorization": AuthServices.getCurrentToken()
            }
        });
    },
    setCurrentUser: (user, session) => {
        user.avatar_url = AuthServices.buildAvatarUrl(user.avatar);

        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('accessToken', session);
        
        AuthServices.saveCookieUser({...user, accessToken: session});
    }
}

export default CoachServices;