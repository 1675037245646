import {
  dangerColor,
  grayColor,
  hexToRgb
} from "assets/jss/material-dashboard-pro-react.js";

import customInputStyle from './customInputStyle';

const fileUploadStyle = theme => ({
  ...customInputStyle,
  formControl: {
    margin: "0 0 17px 0",
    paddingTop: "27px",
    position: "relative",
    verticalAlign: "unset",
    flexDirection: "row",
    flexWrap: "wrap",
    "& svg,& .fab,& .far,& .fal,& .fas,& .material-icons": {
      color: grayColor[14]
    }
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  inputRoot: {
    flex: "1 1 auto",
    marginRight: "10px"
  },
  label: {
    flex: "0 1 auto"
  },
  flexBreak: {
    flexBasis: "100%",
    height: "0"
  },
  helpText: {
    width: "100%"
  },
  clearButton: {
    "& svg": {
      color: dangerColor[0] + ' !important'
    },
    "&:hover": {
      "backgroundColor": 'rgba(' + hexToRgb(dangerColor[0]) + ', 0.04)'
    }
  },
  cursorPointer: {
    cursor: 'pointer'
  }
});

export default fileUploadStyle;
