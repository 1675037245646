import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import cx from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import Footer from "components/Footer/Footer.js";

import routes from "routes.js";

import styles from "assets/jss/material-dashboard-pro-react/layouts/authStyle.js";
import bg_login from "assets/img/bg-login.jpg";

const useStyles = makeStyles(styles);

export default function Pages(props) {
  const { ...rest } = props;
  // ref for the wrapper div
  const wrapper = React.createRef();
  // styles
  const classes = useStyles();

  React.useEffect(() => {
    document.body.style.overflow = "unset";
    // Specify how to clean up after this effect:
    return function cleanup() {};
  });

  const getRoute = () => {
    return window.location.pathname === "/auth/signup"  || window.location.pathname === "/auth/signup-complete";
  };
  
  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const fullPage = classes.fullPage 
  + ' ' 
  + cx({[classes.signupFullPage]: getRoute()});

  return (
    <div>
      <div className={classes.wrapper} ref={wrapper}>
        <div
          style={{ backgroundImage: "url(" + bg_login + ")" }}
          className={fullPage}
        >
          <Switch>
            {getRoutes(routes)}
            <Redirect from="/auth" to="/auth/login" />
          </Switch>
        </div>
      </div>
    </div>
  );
}
