/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:b06a642d-8cf9-404e-a8c7-ea04ed5126e7",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_WIk2SnfXe",
    "aws_user_pools_web_client_id": "3srblfk5vfec69hj6mkpchuh25",
    "oauth": {},
    "identityPoolId": "us-east-2:e1c9f815-e1e5-472f-b419-2f10363ad71b",
    "region": "us-east-2",
    "identityPoolRegion": "us-east-2",
    "userPoolId": "us-east-2_Z52WYAmy3",
    "userPoolWebClientId": "5jh1oi793lr88ns2pm67gfi803",
    "mandatorySignIn": false,
    "authenticationFlowType": "USER_SRP_AUTH",
    "Auth": {
        "identityPoolId": "us-east-2:e1c9f815-e1e5-472f-b419-2f10363ad71b",
        "region": "us-east-2",
        "identityPoolRegion": "us-east-2",
        "userPoolId": "us-east-2_Z52WYAmy3",
        "userPoolWebClientId": "5jh1oi793lr88ns2pm67gfi803",
        "mandatorySignIn": false,
        "authenticationFlowType": "USER_SRP_AUTH",
        "oauth": {}
    }
};


export default awsmobile;
