import React, { useState, useEffect } from "react";
import {strings as translate} from 'locale';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import LoginForm from "components/AuthForms/LoginForm";
import SendCodeForm from "components/AuthForms/SendCodeForm";
import ChangePasswordForm from "components/AuthForms/ChangePasswordForm";

// services
import AuthServices from "services/AuthServices";

import {UserContext} from "providers/UserProvider";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

import routes from "../../routes";

// hooks react redux
import { useDispatch, useSelector } from "react-redux";

// importamos la acción
import { getMasters } from "../../redux/masters"

const useStyles = makeStyles(styles);

export default function LoginPage(props) {
  const [action, setAction] = useState('login');
  const [email, setEmail] = useState('');
  const useUserContext = React.useContext(UserContext);

  const dispatch = useDispatch()

  const my_masters = useSelector(store=>store.masters.object)

  useEffect(() => {
    dispatch(getMasters())
  }, [])

  useEffect(() => {
    // verificamos si existe el user en el localStorage
    const user = AuthServices.getUser();
    const {layout, path} = routes[0];
    if (user) {
      useUserContext.updateUser();
      props.history.push(`${layout}${path}`);
    }
  }, [props.history, useUserContext]);

  const classes = useStyles();

  const handlerShowForgotPassword = () => {
    setAction('forgot_password');
  };

  const handleOnSendCode = (email) => {
    setEmail(email)
    setAction('change_password');
  }

  const handleOnChangePassword = () => {
    setAction('login');
  }

  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={6}>
          { action === 'login' &&
          <LoginForm  onForgotPassword={handlerShowForgotPassword} />
          }
          { action === 'forgot_password' && 
          <SendCodeForm onSend={handleOnSendCode} onRememberPassword={handleOnChangePassword} />
          }
          { action === 'change_password' &&
          <ChangePasswordForm onSend={handleOnChangePassword} email={email} />
          }
        </GridItem>
      </GridContainer>
    </div>
  );
}
