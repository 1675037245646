import React, { useEffect, useState } from 'react'
import moment from 'moment'
import 'moment/locale/es';
// nodejs library that concatenates classes
import classNames from "classnames";

import AuthServices from '../../services/AuthServices'
import CoachServices from '../../services/CoachServices'

import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';

import banner from '../../assets/img/banners/banner_promocional_et.png'
import banner_platinum from '../../assets/img/banners/banner_promocional_platinum-habilitado.png'
import curso_gratis from '../../assets/img/varios/Aprende_a_usar_ET.jpg'

import { Card, Fade, makeStyles, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core'
import Button from 'components/CustomButtons/Button'
import CardHeader from 'components/Card/CardHeader'
import CardBody from 'components/Card/CardBody'
import controlPanelStyle from 'assets/jss/material-dashboard-pro-react/views/controlPanelStyle'
import LoadingIndicator from 'components/Loader/LoadingIndicator';

import { primaryColor } from 'assets/jss/material-dashboard-pro-react.js'
import { trackPromise } from 'react-promise-tracker';
import CoachPanel from './CoachPanel';
import AthletePanel from './AthletePanel';
import UserPanel from './UserPanel';

const useStyles = makeStyles(controlPanelStyle);
moment.locale('es')

const OrangeSwitch = withStyles({
    switchBase: {
        color: primaryColor[0],
        '&$checked': {
            color: primaryColor[0],
        },
        '&$checked + $track': {
            backgroundColor: primaryColor[0],
        },
    },
    checked: {},
    track: {},
})(Switch);

const ControlPanel = () => {

    const authUser = AuthServices.getUser();
    const user = authUser.user;

    const [posts, setPosts] = useState([])
    const [panelCoach, setPanelCoach] = useState(true)
    const [coachLicenseType, setCoachLicenseType] = useState('basic')
    
    const [isLoading, setIsLoading] = useState(true)
    const classes = useStyles();

    useEffect(() => {

        const getLatestPosts = async () => {
            try {
                const res = await fetch('https://blog.endurancegroup.org/wp-json/wp/v2/posts?_embed&per_page=2');
                const json = await res.json()
    
                setPosts(json);
            } catch (error) {
                console.log(error);
            }
        }
        setCoachLicenseType(user.license_type)
        trackPromise(getLatestPosts()).then(()=>{
            setIsLoading(false)
        });
    }, [])

    const openUrl = (url, target) => {
        window.open(url, target ? target : '_self', 'noopener,noreferrer')
    }

    const openCourse = (course_id) => {
        AuthServices.getIEGToken().then(function (params) {
            window.location.href = `${process.env.REACT_APP_UNI_URL}course/${course_id}${params}`;
        });

    }

    const handleSwitchChange = (event) => {
        setPanelCoach(!event.target.checked)
    }

    const panelCoachClass = classNames({
        [classes.p0]: true,
        [classes.active]: panelCoach === true
    })

    const panelAthleteClass = classNames({
        [classes.p0]: true,
        [classes.active]: panelCoach === false
    })

    return (
    <>
        <LoadingIndicator
            type="TailSpin"
            color="#2e82ef"
            height={50}
            width={50}
            />
        
        { !isLoading &&
        <Fade in>
            <GridContainer direction="column" >
                <GridItem>
                    <h2 className={classes.userName}>Hello {user.first_name}</h2>
                    <h3 className={classes.welcome}>Welcome to Endurance Group</h3>
                </GridItem>
            
                { !isLoading && coachLicenseType !== 'platinum' &&
                <GridItem className={classes.bannerContainer}>
                    <div className={classes.bannerContent}>
                        <img src={banner} className={classes.bannerImage} />
                    </div>
                </GridItem>
                }
                { !isLoading && coachLicenseType === 'platinum' &&
                <GridItem className={classes.bannerContainer}>
                    <div className={classes.bannerContent}>
                        <img src={banner_platinum} className={classes.bannerImage} />
                    </div>
                </GridItem>
                }
                <GridItem className={classes.mainPanel}>
                    <GridContainer direction="row" justify="flex-start" alignItems="stretch">

                        <GridItem xs={12} sm={4} container direction="column">
                            <Card>
                                <CardHeader className={classes.cardHeader}>
                                    <h4 className={classes.cardTitle}>POSTS</h4>
                                </CardHeader>
                                <CardBody className={classes.cardBody}>
                                    <p className={classes.cardText}>Endurance Blog</p>
                                    <Button
                                        onClick={() => openUrl('https://blog.endurancegroup.org/', '_blank')}
                                        target="_blank"
                                        variant="outlined"
                                        color="primary"
                                    >
                                        {'VISIT BLOG'}
                                    </Button>
                                </CardBody>
                                <CardBody className={classes.cardBody}>
                                    {posts.map((post, key) => (
                                        <GridContainer key={key} className={classes.postContainer}>
                                            <GridItem container direction="column">
                                                <div className={classes.postHeader}>
                                                    <div className={classes.postDate}>{moment(post.date).format('DD')}<br />{moment(post.date).format('MMM')}</div>
                                                    <div className={classes.postImageBox}>
                                                        <img src={post._embedded['wp:featuredmedia'][0].source_url} alt="" className={classes.postImage} />
                                                    </div>
                                                </div>
                                                <div className={classes.postContent}>
                                                    <div className={classes.postTitle} onClick={() => openUrl(post.link, '_blank')}>
                                                        {post.title.rendered}
                                                    </div>
                                                    {post.tags &&
                                                        <div>
                                                            {
                                                                post.tags.map((tag, key) => (
                                                                    <div>{{ tag }}</div>
                                                                ))
                                                            }
                                                        </div>}
                                                </div>
                                            </GridItem>
                                        </GridContainer>
                                    ))}
                                </CardBody>
                            </Card>
                            <Card className={classes.cardSpace} style={{flex:1}}>
                                <CardHeader className={classes.cardHeader}>
                                    <h4 className={classes.cardTitle}>Curso</h4>
                                </CardHeader>
                                <CardBody className={classes.cardBody}>
                                    <p className={classes.cardText}>Para Entrenadores</p>
                                    <Button
                                        onClick={() => openUrl(process.env.REACT_APP_UNI_URL, '_blank')}
                                        target="_blank"
                                        variant="outlined"
                                        color="primary"
                                    >
                                        {'IR A UNIVERSITY'}
                                    </Button>
                                </CardBody>
                                <CardBody className={classes.cardBody}>
                                    <GridContainer className={classes.courseContainer}>
                                        <GridItem container direction="column">
                                            <img src={curso_gratis} alt="" className={classes.courseImage} />
                                            <div className={classes.courseContent}>
                                                <h3>
                                                    Curso Gratis<br />
                                                    <small>Aprende a utilizar Endurance Tool</small>
                                                </h3>
                                                <h4>VALOR $0</h4>
                                                <Button color="primary" onClick={() => openCourse(83)}>
                                                    CURSAR
                                                </Button>
                                            </div>
                                        </GridItem>
                                    </GridContainer>
                                </CardBody>
                            </Card>
                        </GridItem>
            
                        <GridItem xs={12} sm={8}>
                            <Card className={classes.cardRight}>
                                <CardHeader className={classes.cardHeader}>
                                    <h4 className={classes.cardTitle}>E TOOL</h4>
                                </CardHeader>
                                <CardBody>
                                    <GridContainer className={classes.switchProfile}>
                                        <GridItem container justify="center">
                                            <Typography component="div">
                                                <GridItem component="label" container alignItems="center">
                                                    <GridItem className={panelCoachClass}>Entrenador</GridItem>
                                                    <GridItem className={classes.p0}>
                                                        <OrangeSwitch
                                                            checked={!panelCoach}
                                                            onChange={handleSwitchChange}
                                                            name="userProfile"
                                                        />
                                                    </GridItem>
                                                    <GridItem className={panelAthleteClass}>Atleta</GridItem>
                                                </GridItem>
                                            </Typography>
                                        </GridItem>
                                    </GridContainer>

                                    { panelCoach &&  !user.is_coach && <UserPanel /> }

                                    { panelCoach &&  user.is_coach && <CoachPanel /> }

                                    { !panelCoach && <AthletePanel /> }

                                </CardBody>
                            </Card>
                        </GridItem>
            
                    </GridContainer>
                </GridItem>
            </GridContainer>
        </Fade>
        }
    </>
    )
}

export default ControlPanel
