import { useContext } from 'react';
import { MainNotificationContext } from '../../providers/MainNotificationProvider';

function useMainNotification() {
  const { 
    notification,
    addInfo,
    removeInfo,
    addError,
    removeError,
    addSuccess,
    removeSuccess,
    addWarning,
    removeWarning
  } = useContext(MainNotificationContext);
  return { 
    notification,
    addInfo,
    removeInfo,
    addError,
    removeError,
    addSuccess,
    removeSuccess,
    addWarning,
    removeWarning
  };
}

export default useMainNotification;