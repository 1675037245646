import React from 'react'
import _ from 'lodash';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import Form from "../CustomForms/Form";

const FormDialog = (props) => {
    const {open, onSent, onSentError} = props;
    const {row, global_action, uniqueId} = props.data;
    const [titleForm, setTitleform] = React.useState('');
    const [callToAction, setCallToAction] = React.useState('');
    const [data, setData] = React.useState({})

    const handleClose = () => {
        if(props.onClose) {
            props.onClose();
        }
    }

    const handleSent = (values) => {
        if(onSent) {
            _.forIn(values, (v, k) => {
                if(!row) return
                if(typeof row[k] === 'object') {
                    row[k] = values[k+'_obj'];
                } else {
                    row[k] = v;
                }
            })
            onSent(row);
        }
    }

    const handleSentError = (e) => {
        if(onSentError) { onSentError(e); }
    }

    React.useEffect(() => {
        if(row) {
            const newData = _.mapValues(row, (o, i) => {
                if(i === 'actions') return null;
                if(typeof o === 'object' && o !== null) {
                    if(o.id) {
                        return o.id;
                    }
                    return o[uniqueId.accessor];
                }
                return o;
            });
            setData(newData)
        }

        if(global_action) {
            setTitleform(global_action.titleform)
            setCallToAction(global_action.calltoaction)
        }
    }, [row, global_action]);

    if(!global_action) {
        return null;
    }

    return (
        <Dialog 
            open={open}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">{titleForm}</DialogTitle>
            <DialogContent>
                <Form
                    name={callToAction}
                    data={{...data, metadata: global_action ? global_action.form : [], global_action}}
                    editForm={global_action.type === 'row'}
                    popUpType={global_action.type}
                    columns="1"
                    showCancel
                    onCancel={handleClose}
                    onSent={handleSent}
                    onSentError={handleSentError}
                />
            </DialogContent>
        </Dialog>
    )
}

export default FormDialog
