import React, { useState } from "react";
import IframeComm from "react-iframe-comm";

import { useParams } from "react-router-dom";
// services
import AuthServices from "services/AuthServices";

const Iframe = (props) => {
  const [url, setUrl] = useState("");
  const { page, id } = useParams();
  const params = useParams();
  let queryStringSearch = props.location.search;

  React.useEffect(() => {
    if (props.config.layout === "/src/et/") {
      // se agregan los parámetros
      const params = queryStringSearch !== '' ? queryStringSearch:'';
      setUrl(
        process.env.REACT_APP_ET_URL +
          props.config.pathname.replace("/src/et/", "/src/#/") +
          params
      );
    } else if (props.config.layout === "/et") {
      setUrl(
        `${process.env.REACT_APP_ET_URL}/src/#/` +
          (page ? page + queryStringSearch : "control-panel")
      );
    } else if (props.config.layout === "/et-academy") {
      if (id) {
        queryStringSearch = id;
      }
      setUrl(
        `${process.env.REACT_APP_UNI_URL}/src/#/` +
          (page ? page + "/" + queryStringSearch : "")
      );
    }
  }, [page]);

  const attributes = {
    src: url,
    width: "100%",
    height: "100%",
    frameBorder: 0,
  };

  const logout = () => {
    AuthServices.logout();
    props.history.push("/auth/login");
  };

  // parent received a message from iframe
  const onReceiveMessage = (e) => {
    if (
      typeof e.data === "string" &&
      (e.origin === process.env.REACT_APP_ET_URL ||
        e.origin === process.env.REACT_APP_UNI_URL)
    ) {
      const response = JSON.parse(e.data);
      let newPath = "";
      if (response.from === "location") {
        if (e.origin === process.env.REACT_APP_ET_URL) {
          newPath = response.url.replace(e.origin + "/src/#/", "/");
        } else if (e.origin === process.env.REACT_APP_UNI_URL) {
          newPath = response.url.replace(e.origin + "/src/#/", "/");
        }

        if (window.location.pathname !== props.config.layout + newPath) {
          // props.history.push(props.config.layout + newPath)
        }
      } else if (response.from === "menu") {
        if (response.url) {
          props.history.push(response.url);
        } else if (response.action === "logout") {
          logout();
        }
      }
    }
  };

  return (
    url !== "" && (
      <IframeComm
        attributes={{
          src: url,
          width: "100%",
          height: "100%",
          frameBorder: 0,
          style: { height: "100vh", width: "100vw" },
          mozallowfullscreen:"",
          webkitallowfullscreen:"",
          allowfullscreen:""
        }}
        handleReceiveMessage={onReceiveMessage}
      />
    )
  );
};

export default Iframe;
