import React from 'react';
// import {useFormikContext} from "formik";
import { useField as useFieldFormik, useFormikContext, getIn } from "formik";
import Datetime from "react-datetime";
import 'moment/locale/es';
import { strings as translate, get_language } from 'locale';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import { Check } from "@material-ui/icons";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// core components
import CustomInput from "../CustomInput/CustomInput";
import FileUpload from "../FileUpload/FileUpload"
import useMainNotification from '../../hooks/useMainNotification';

// services
import MainServices from "../../services/MainServices";

import styles from './styles';
import classNames from "classnames";

const useStyles = makeStyles(styles);

function InputForm(props) {
    const formikContext = useFormikContext();
    const { data, inputRootCustomClasses } = props;
    const { status, setStatus } = formikContext;
    const [{ onBlur: onBlurFormik }] = useFieldFormik(data.accessor);
    const apiError = getIn(status, data.accessor);
    const classes = useStyles();
    const [selectData, setSelectData] = React.useState([]);
    const [dataLoading, setDataLoading] = React.useState(false);

    // buscamos campos requeridos
    const isRequired = () => {
        if (!data.validations){ return false; }
        const item = data.validations.filter(item=>item.type==='required').pop();
        return (item && item.type === 'required') ? true : false;
    }

    const required = isRequired();

    const success = formikContext.errors[data.accessor] === undefined && formikContext.touched[data.accessor] && formikContext.values[data.accessor] !== '' && required;
    const error = formikContext.errors[data.accessor] !== undefined && formikContext.touched[data.accessor] && required;
    const { addError } = useMainNotification();

    const onBlurMemo = React.useCallback(
        e => {
            setStatus({
                ...status,
                [data.accessor]: null
            });
            onBlurFormik(e);
        },
        [status, data.accessor, setStatus, onBlurFormik]
    );
        
    React.useEffect( () => {
        const fecthData = async () => {
            try {
                const res = await MainServices.list(data.service);
                
                setSelectData(res.data.data);
            } catch (e) {
                if(e.message) {
                  addError(e.message, null, {messageLength:null});
                } else {
                  console.log(e);
                }
            } finally {
                setDataLoading(false);
            }
        };
        if (data.type === 'select') {
            setDataLoading(true);
            if (data.service) {
                fecthData();
            } else if(data.options.length){
                setSelectData(data.options);
                setDataLoading(false);
            }
        }
    }, [addError, data]);
    
    if(data.type === 'hidden') {
        const value = typeof formikContext.values[data.accessor] === 'boolean' ? !formikContext.values[data.accessor] : (formikContext.values[data.accessor] || '')
        return <CustomInput
            id={data.accessor}
            name={data.accessor}
            value={formikContext.values[data.accessor] || ''}
            inputProps={{
                onChange: formikContext.handleChange,
                type: data.type,
                value: formikContext.values[data.accessor] || '',
                name: data.accessor
            }}
        />
    }
    

    if (data.type === 'image') {
        return (
            <FileUpload
                id={data.accessor}
                name={data.accessor}
                labelText={data.header + (required ? ' *' : '')}
                buttonText={translate.upload_image}
                value={formikContext.values[data.accessor] || ''}
                inputProps={{
                    placeholder: translate.select_image,
                    value:formikContext.values[data.accessor] || ''
                }}
                accept="image/*"
                formControlProps={{
                    fullWidth: true
                }}
                onChange={(e) => {formikContext.handleChange(e);}}
                onBlur={(e)=>{formikContext.handleBlur(e);}}
                success={success}
                error={error || (apiError ? true : false)}
                helperText={error || apiError ? formikContext.errors[data.accessor] || apiError : ''}
            />)
    }

    if(data.type === 'select') {
        const formControlClasses = classNames({
            [" " + classes.selectFormControlError]: error,
            [" " + classes.selectFormControlSuccess]: success && !error
          });
        const labelClasses = classNames({
            [" " + classes.labelRootError]: error,
            [" " + classes.labelRootSuccess]: success && !error
          });
        const helpTextClasses = classNames({
            [classes.labelRootError]: error || apiError,
            [classes.labelRootSuccess]: success && !error
        });
        const selectValue = () => {
            let value = '';
            if (selectData.length > 0 && formikContext.values[data.accessor]) {
                if (formikContext.values[data.accessor].id) {
                    value = formikContext.values[data.accessor].id
                } else {
                    value = formikContext.values[data.accessor];
                }
                formikContext.values[data.accessor+'_obj'] = selectData.filter(item=>item.id === formikContext.values[data.accessor]).pop();
            }
            return value;
        }
        return <FormControl fullWidth className={classes.selectFormControl + ' ' + formControlClasses}>
            <InputLabel
                htmlFor={data.accessor}
                className={classes.selectLabel + ' ' + labelClasses}
            >
                {dataLoading ? translate.loading + '...' : data.header + (required ? ' *' : '')}
            </InputLabel>
            <Select
                MenuProps={{
                    className: classes.selectMenu
                }}
                classes={{
                    select: classes.select
                }}
                value={selectValue()}
                onChange={formikContext.handleChange}
                onBlur={formikContext.handleBlur}
                inputProps={{
                    name: data.accessor,
                    id: data.accessor,
                    disabled: data.editable === false
                }}
                disabled={dataLoading}
            >
                <MenuItem
                    disabled
                    classes={{
                        root: classes.selectMenuItem
                    }}
                >
                    {data.header}
                </MenuItem>
                {selectData.map( item => (
                <MenuItem
                    key={item.id}
                    classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                    }}
                    value={item.id}
                >
                    {item.name}
                </MenuItem>
                ))}
            </Select>
            {error || apiError ? (
                <FormHelperText id={data.accessor + "-text"} className={helpTextClasses}>
                    {formikContext.errors[data.accessor] || apiError}
                </FormHelperText>
            ) : null}
      </FormControl>
    }

    if (data.type === 'date') {

        const underlineClasses = classNames({
            [classes.underlineError]: error || apiError,
            [classes.underlineSuccess]: success && !error,
            [classes.underline]: true,
            [classes.whiteUnderline]: false
        });
        const marginTop = classNames({
            [inputRootCustomClasses]: inputRootCustomClasses !== undefined
        });
        const inputClasses = classNames({
            [classes.input]: true,
            [classes.whiteInput]: false
        });
        const helpTextClasses = classNames({
            [classes.labelRootError]: error || apiError,
            [classes.labelRootSuccess]: success && !error
        });

        return (<>
            <FormControl fullWidth className={classes.formControl}>
                <InputLabel
                    className={
                        classes.labelRoot + ' ' +
                        (formikContext.touched[data.accessor] ? 'MuiInputLabel-shrink MuiFormLabel-filled' : '')
                    }
                    htmlFor={data.accessor}
                    shrink={formikContext.values[data.accessor] ? true : false}
                >
                    {data.header + (required ? ' *' : '')}
                </InputLabel>
                <Datetime
                    closeOnSelect
                    inputProps={{
                        placeholder: get_language === "es" ? "dd/mm/yyyy" : "mm/dd/yyyy",
                        id: data.accessor,
                        name: data.accessor,
                        autoComplete: "off",
                        className: 'MuiInputBase-input MuiInput-input ' + classes.input,
                        value: formikContext.values[data.accessor] || '',
                        disabled: data.editable === false
                    }}
                    value={formikContext.values[data.accessor] || ''}
                    className={
                        'MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-formControl MuiInput-formControl' +
                        inputClasses + ' ' +
                        marginTop + ' ' +
                        underlineClasses
                    }
                    onChange={e => {
                        const value = (typeof e === 'object') ? e.format("L") : e;
                        formikContext.setFieldTouched(data.accessor, true);
                        formikContext.setFieldValue(data.accessor, value);
                    }}
                    onBlur={e=>{
                        onBlurMemo(e)
                    }}
                    timeFormat={data.type === 'datetime'}
                    locale={ get_language }
                />
                {error || apiError ? (
                    <FormHelperText id={data.accessor + "-text"} className={helpTextClasses}>
                        {formikContext.errors[data.accessor] || apiError}
                    </FormHelperText>
                ) : null}
            </FormControl>
        </>)
    }

    if (data.type === 'checkbox') {
        const helpTextClasses = classNames({
            [classes.labelRootError]: error || apiError,
            [classes.labelRootSuccess]: success && !error
        });
        return (
            <FormControl fullWidth className={classes.formControl}>
                <FormControlLabel
                    control={
                        <Checkbox
                            name={data.accessor}
                            value={formikContext.values[data.accessor]}
                            tabIndex={-1}
                            onChange={formikContext.handleChange}
                            checkedIcon={<Check className={classes.checkedIcon} />}
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                                checked: classes.checked,
                                root: classes.checkRootSmall
                            }}
                            checked={formikContext.values[data.accessor] ? true : false}
                            disabled={data.editable === false}
                        />
                    }
                    classes={{
                        label:
                            classes.labelRoot + " " +
                            classes.label +
                            (error
                                ? " " + classes.labelError
                                : "")
                    }}
                    label={data.header + (required ? ' *' : '')}
                />
                {error || apiError ? (
                    <FormHelperText id={data.accessor + "-text"} className={helpTextClasses}>
                        {formikContext.errors[data.accessor] || apiError}
                    </FormHelperText>
                ) : null}
            </FormControl>
        );
    }

    return (
        <CustomInput
            id={data.accessor}
            name={data.accessor}
            labelText={data.header + (required ? ' *' : '')}
            value={formikContext.values[data.accessor] || ''}
            inputProps={{
                onChange: formikContext.handleChange,
                onBlur: formikContext.handleBlur,
                type: data.type,
                value: formikContext.values[data.accessor] || '',
                multiline: data.multiline,
                rowsMax:"4",
                required: required,
                disabled: data.editable === false
            }}
            formControlProps={{
                fullWidth: true
            }}
            success={success}
            error={error || (apiError ? true : false)}
            helperText={error || apiError ? formikContext.errors[data.accessor] || apiError : ''}
        />
    );
}

export default InputForm;
