import React from 'react'

const NewProfile = () => {
    return (
        <div>
            paso 1 / 2
        </div>
    )
}

export default NewProfile
