import React from "react";
import { trackPromise } from 'react-promise-tracker';
import { withRouter } from 'react-router-dom';
import { strings as translate } from 'locale';
import _ from 'lodash';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import CustomTable from "components/CustomTable/CustomTable";
import Button from "components/CustomButtons/Button";
import LoadingIndicator from "components/Loader/LoadingIndicator";
import useMainNotification from '../../hooks/useMainNotification';

// services
import MainServices from "../../services/MainServices";

// contexts
import {GenericPageContext} from "../../providers/GenericPageProvider";

import styles from './styles';

import CustomDialog from '../../components/CustomDialog/CustomDialog';
import GlobalFilters from "../../components/CustomTable/GlobalFilters";

const useStyles = makeStyles(styles);

const List = (props) => {
    const config = React.useContext(GenericPageContext)
    const model = config.model;
    const path = props.match.path;
    const classes = useStyles();
    const [data, setData] = React.useState(() => []);
    const [loaded, setLoaded] = React.useState(false);
    const [error, setError] = React.useState('');
    const { addError, addSuccess } = useMainNotification();
    const [skipPageReset, setSkipPageReset] = React.useState( false );
    const [openModal, setOpenModal] = React.useState( false );
    const [showAddButton, setShowAddButton] = React.useState( true );
    const [modalData, setModalData] = React.useState( {} );
    const [global_filters, setGlobalFilters] = React.useState('');

    async function loadData() {
        try {
            const res = await MainServices.list(model);
            if(!res.data.error) {
              setData(res.data) 
              if(res.data.config !== undefined && res.data.config.button_add !== undefined) {
                setShowAddButton(res.data.config.button_add);
              }
            }
        } catch (e) {
            if ( e.message ) {
                setError(e.message);
                addError(e.message, null, {messageLength:null});
            } else {
                console.log(e)
            }
        }
    }

    React.useEffect(() => {
        trackPromise(loadData())
            .then(() => {
                setLoaded(true);
            });

        return () => { MainServices.cancel(); }
    }, [addError, model]);

    const deleteItem = async (id) => {
        try {
            const res = await MainServices.delete(model, id)
            return res;
        } catch (e) {
          if ( e.message ) {
              // setError(e.message);
              addError(e.message, null, {messageLength:null});
          } else {
              console.log(e)
          }
        }
    }

    const updateItem = async (model, id, column, value) => {
        try {
          await MainServices.update(model, {id: id, [column]: value});
        } catch (error) {
          console.log(error)
        }
    }

    // used when we have an editable cell
    const updateMyData = (rowIndex, columnId, value, rowData) => {
      updateItem(model, rowData.id, columnId, value);
      // We also turn on the flag to not reset the page
      setSkipPageReset(true)
      setData(old => {
        const rows = old.data.map((row, index) => {
          if (index === rowIndex) {
            return {
              ...old.data[rowIndex],
              [columnId]: value,
            }
          }
          return row
        });
        return { ...old, rows};
      })
    }

    const handleDelete = async (id) => {

      let promise = new Promise((resolve, reject) => {
        deleteItem(id).then((res)=>{
          if(res && !res.error) {
            addSuccess('El registro fue borrado exitosamente.')
            resolve('El registro fue borrado exitosamente.')
          }
        });
      });
      trackPromise(promise);
      let result = await promise;

      return result;
    }

    const onOpenPopupService = (item) => {
      setModalData(item);
      setOpenModal(true);
    }
    const handleCloseModal = () => {
      setModalData({});
      setOpenModal(false);
    }

    const handleUpdateTableRow = (newRow) => {
      trackPromise(loadData())
      .then(() => {
          setLoaded(true);
      });
    }

    const handleSubmitFilter = (data) => {
      const filtered = {}
      let filters = '';
      _.each(data, (value, key)=>{
        if(key.match(/(.+)_obj$/)) return;
        if(value!=='') Object.assign(filtered, {[key]:value})
      });
      filters = JSON.stringify(filtered);
      const filters_b64 = Buffer.from(filters).toString("base64");
      setGlobalFilters(filtered)

      async function filteredData() {
        try {
            const res = await MainServices.filteredList(model, `?filter=${filters_b64}`);
            if(!res.data.error) {
              setData(res.data);
            }
        } catch (e) {
            if ( e.message ) {
                setError(e.message);
                addError(e.message, null, {messageLength:null});
            } else {
                console.log(e)
            }
        }
      }
      trackPromise(filteredData())
      .then(() => {
          setLoaded(true);
      });
    }

    return (
      <>
        <LoadingIndicator
          type="TailSpin"
          color="#2e82ef"
          height={50}
          width={50}
        />

        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="primary" icon>
                <CardIcon color="primary">
                  <Assignment />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                {
                  loaded && data.config && data.config.title ? 
                  data.config.title
                  :
                  translate.list
                }
                </h4>
              </CardHeader>
              <CardBody>
                {
                  loaded && !error && <GlobalFilters data={data} onSubmit={handleSubmitFilter} onCancel={handleUpdateTableRow} />
                }
                {
                  loaded && showAddButton &&
                  <Button
                    className="btn btn-primary"
                    onClick={() => props.history.push(`${path}/add`)}
                  >
                      {translate.add}
                  </Button>
                }

                {
                  (loaded && !error) ? 
                  <CustomTable 
                    data={data}
                    onRowDeleted={handleDelete}
                    paginationBottom={true}
                    updateMyData={updateMyData}
                    skipPageReset={skipPageReset}
                    onOpenPopupService={onOpenPopupService}
                    actionButtons={true}
                    updateTableRow={handleUpdateTableRow}
                    globalFiltersForm={global_filters}
                  />
                  : (loaded && error) ? 
                  <div className={classes.textError}>
                    {translate.formatString(translate.autogenerate_table_error, <br />)}
                  </div>
                  :
                  null
                }
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        
        <CustomDialog
          fullWidth={true}
          maxWidth={'xl'}
          scroll={'paper'}
          open={openModal}
          onClose={handleCloseModal}
          data={modalData} />
      </>
    );
}
export default withRouter(List);
