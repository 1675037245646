import React, { useState, useReducer, useEffect } from "react";
import {strings as translate} from 'locale';

import { withRouter } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";

// @material-ui/icons
import Email from "@material-ui/icons/Email"
import FormHelperText from "@material-ui/core/FormHelperText";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";

// services
import AuthServices from 'services/AuthServices';

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

import logo_header from "assets/img/logos/LogoEnduranceTool_pass.png";

const useStyles = makeStyles(styles);

const SendCodeForm = (props) => {
  const { onRememberPassword } = props;
  const [cardAnimaton, setCardAnimation] = useState("cardHidden");
  const [inputValues, setInputValues] = useReducer(
      (state, newState) => ({ ...state, ...newState }),
      {email: ''}
  );
  const [apiError, setApiError] = useState('');
  const [sendEmail, setSendEmail] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    let id = setTimeout(function() {
      setCardAnimation("");
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });

  const setInput = (e) => {
    const { name, value } = e.target;
    setInputValues({ [name]: value });
  }

  const sendCode = async (e) => {
    e.preventDefault();
    
    if (!inputValues.email.trim() || !validateEmail(inputValues.email)) {
      setApiError( translate.incorrect_email );
      return;
    }
    setApiError( '' );

    try {
      const res = await AuthServices.forgotPassword(inputValues.email);
      if ( !res.data.error ) {
        console.log(res.data);
        setSendEmail(true);
      }
    } catch (error) {
      console.log(error)
      setApiError(error.message);
    }
  }

  const validateEmail = (email) => {
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
      return true
    }
    return false
  }

  const handleLoginClick = () => {
    if(onRememberPassword) {
      onRememberPassword();
    }
  }

  return (
    <form onSubmit={sendCode}>
      <Card login className={classes[cardAnimaton]}>
        <CardHeader
          className={`${classes.cardHeaderForgot} ${classes.textCenter}`}
          color="primary"
        >
          <img src={logo_header} className={classes.logoHader} />
        </CardHeader>
        <CardBody className={classes.cardBodyForgot}>
          <div className={classes.cardBodyContent}>
          <h3 className={classes.forgotTitle}>¿Olvidaste tu contraseña?</h3>
          {!sendEmail && 
          <>
            <p className={classes.forgotText}>Ingresa tu dirección de correo y te enviaremos un email con instrucciones para recuperar tu contraseña.</p>
            <CustomInput
              labelText={translate.email}
              id="email"
              formControlProps={{
                fullWidth: true
              }}
              value={inputValues.email}
              inputProps={{
                onChange: setInput,
                name: "email",
                endAdornment: (
                  <InputAdornment position="end">
                    <Email className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                )
              }}
            />
            {apiError && <FormHelperText error>{apiError}</FormHelperText>}
          </>
          }

          {sendEmail &&
          <>
            <p className={classes.forgotText}>
              Revisa tu correo y sigue las instrucciones. 
              <Button 
                color="default" 
                size="sm" 
                type="button"
                className={classes.forgotButtonEnter}
                onClick={handleLoginClick}
              >
                  INGRESAR
              </Button>
            </p>
          </>
          }
          </div>
        </CardBody>
        <CardFooter className={classes.cardFooterForgot}>
          { !sendEmail &&
          <>
            <Button color="primary" size="lg" type="submit">
              {translate.send}
            </Button>
            <p className={classes.rememberText} onClick={handleLoginClick}>¿La recordaste? Ingresa aquí</p>
            <div className={classes.socialMedia}>
                <a href="https://www.instagram.com/internationalendurancegroup" target="_blank"><i className="fab fa-instagram"></i></a>
                <a href="https://twitter.com/IEnduranceGroup" target="_blank"><i className="fab fa-twitter"></i></a>
                <a href="https://www.linkedin.com/company/international-endurance-group/" target="_blank"><i className="fab fa-linkedin-in"></i></a>
                <a href="https://www.facebook.com/InternationalEnduranceGroup/" target="_blank"><i className="fab fa-facebook-f"></i></a>
            </div>
          </>
          }
        </CardFooter>
      </Card>
    </form>
  )
}

export default withRouter(SendCodeForm)
