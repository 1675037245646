import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import {strings as translate} from '../../locale';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Divider from "@material-ui/core/Divider";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Hidden from "@material-ui/core/Hidden";
import Popper from "@material-ui/core/Popper";

// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Notifications from "@material-ui/icons/Notifications";
import AppsIcon from '@material-ui/icons/Apps';

// core components
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";

// services
import AuthServices from "services/AuthServices";
import { Auth } from 'aws-amplify';

// contexts
import { UserContext } from "providers/UserProvider";

import avatar from "assets/img/faces/default.jpg";

import logoET from "assets/img/logos/ET_nav.png";
import logoETAcademy from "assets/img/logos/et_academy.png";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const userContext = React.useContext(UserContext)
  const currentUser = userContext.user.user;
  
  const [openApps, setOpenApps] = React.useState(null);
  const handleClickApps = event => {
    if (openApps && openApps.contains(event.target)) {
      setOpenApps(null);
    } else {
      setOpenApps(event.currentTarget);
    }
  };
  const handleCloseApps = () => {
    setOpenApps(null);
  };
  const [openNotification, setOpenNotification] = React.useState(null);
  const handleClickNotification = event => {
    if (openNotification && openNotification.contains(event.target)) {
      setOpenNotification(null);
    } else {
      setOpenNotification(event.currentTarget);
    }
  };
  const handleCloseNotification = () => {
    setOpenNotification(null);
  };
  const [openMenu, setOpenMenu] = React.useState(null);
  const handleClickMenu = event => {
    if (openMenu && openMenu.contains(event.target)) {
      setOpenMenu(null);
    } else {
      setOpenMenu(event.currentTarget);
    }
  };
  const handleCloseMenu = () => {
    setOpenMenu(null);
  };

  async function handleLogout() {
    try {
        await Auth.signOut();
        AuthServices.logout();
        setOpenMenu(null);
        props.history.push('/auth/login')
    } catch (error) {
        console.log('error signing out: ', error);
    }
  }
  const classes = useStyles();
  const { rtlActive } = props;
  const dropdownItem = classNames(classes.dropdownItem, classes.primaryHover, {
    [classes.dropdownItemRTL]: rtlActive
  });
  const wrapper = classNames({
    [classes.wrapperRTL]: rtlActive
  });
  const managerClasses = classNames({
    [classes.managerClasses]: true
  });
  const goToProfile = () => {
    props.history.push('/et/perfil')
  }
  const goToSettings = () => {
    props.history.push('/et/configuracion')
  }
  const goToET = () => {
    handleCloseApps()
    props.history.push('/et')
  }
  const goToUni = () => {
    handleCloseApps()
    props.history.push('/et-academy')
  }
  return (
    <div className={wrapper}>
      <div className={managerClasses}>
        <Button
          color="transparent"
          simple
          aria-label="AppsIcon"
          justIcon
          onClick={handleClickApps}
          className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
          muiClasses={{
            label: rtlActive ? classes.labelRTL : ""
          }}
        >
          <AppsIcon
            className={
              classes.headerLinksSvg +
              " " +
              (rtlActive ? classes.links + " " + classes.linksRTL : classes.links)
            }
          />
          <Hidden mdUp implementation="css">
            <span
              onClick={handleClickApps}
              className={classes.linkText}
            >
              Apps
            </span>
          </Hidden>
        </Button>
        <Popper
          open={Boolean(openApps)}
          anchorEl={openApps}
          transition
          disablePortal
          placement="bottom"
          className={classNames({
            [classes.popperClose]: !openApps,
            [classes.popperResponsive]: true,
            [classes.popperNav]: true
          })}
        >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            id="notification-menu-list"
            style={{ transformOrigin: "0 0 0" }}
          >
            <Paper className={classes.dropdown}>
              <ClickAwayListener onClickAway={handleCloseApps}>
                <div className={classes.buttonApps}>
                  <Button
                    color="transparent"
                    justIcon
                    round
                    onClick={goToET}
                  >
                    <img src={logoET} className={classes.logoApps} />
                  </Button>
                  <Button
                    color="transparent"
                    justIcon
                    round
                    onClick={goToUni}
                  >
                    <img src={logoETAcademy} className={classes.logoApps} />
                  </Button>
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      </div>

      {/* START Notificaciones */}
      {/* <div className={managerClasses}>
        <Button
          color="transparent"
          justIcon
          aria-label="Notifications"
          aria-owns={openNotification ? "notification-menu-list" : null}
          aria-haspopup="true"
          onClick={handleClickNotification}
          className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
          muiClasses={{
            label: rtlActive ? classes.labelRTL : ""
          }}
        >
          <Notifications
            className={
              classes.headerLinksSvg +
              " " +
              (rtlActive
                ? classes.links + " " + classes.linksRTL
                : classes.links)
            }
          />
          <span className={classes.notifications}>5</span>
          <Hidden mdUp implementation="css">
            <span
              onClick={handleClickNotification}
              className={classes.linkText}
            >
              Notificaciones
            </span>
          </Hidden>
        </Button>
        <Popper
          open={Boolean(openNotification)}
          anchorEl={openNotification}
          transition
          disablePortal
          placement="bottom"
          className={classNames({
            [classes.popperClose]: !openNotification,
            [classes.popperResponsive]: true,
            [classes.popperNav]: true
          })}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              id="notification-menu-list"
              style={{ transformOrigin: "0 0 0" }}
            >
              <Paper className={classes.dropdown}>
                <ClickAwayListener onClickAway={handleCloseNotification}>
                  <MenuList role="menu">
                    <MenuItem
                      onClick={handleCloseNotification}
                      className={dropdownItem}
                    >
                      {rtlActive
                        ? "إجلاء أوزار الأسيوي حين بل, كما"
                        : "Mike John responded to your email"}
                    </MenuItem>
                    <MenuItem
                      onClick={handleCloseNotification}
                      className={dropdownItem}
                    >
                      {rtlActive
                        ? "شعار إعلان الأرضية قد ذلك"
                        : "You have 5 new tasks"}
                    </MenuItem>
                    <MenuItem
                      onClick={handleCloseNotification}
                      className={dropdownItem}
                    >
                      {rtlActive
                        ? "ثمّة الخاصّة و على. مع جيما"
                        : "You're now friend with Andrew"}
                    </MenuItem>
                    <MenuItem
                      onClick={handleCloseNotification}
                      className={dropdownItem}
                    >
                      {rtlActive ? "قد علاقة" : "Another Notification"}
                    </MenuItem>
                    <MenuItem
                      onClick={handleCloseNotification}
                      className={dropdownItem}
                    >
                      {rtlActive ? "قد فاتّبع" : "Another One"}
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div> */}
      {/* END Notificaciones */}

      <div className={managerClasses}>
        <Button
          color="transparent"
          aria-label="Person"
          justIcon
          aria-owns={openMenu ? "profile-menu-list" : null}
          aria-haspopup="true"
          onClick={handleClickMenu}
          className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
          muiClasses={{
            label: rtlActive ? classes.labelRTL : ""
          }}
        >
        <div className={classes.photo}>
          <img 
            src={currentUser.avatar_url ? currentUser.avatar_url : avatar} 
            className={classes.avatarImg} 
            alt={currentUser.first_name ? currentUser.first_name + ' ' + currentUser.last_name : 'avatar'}
          />
        </div>
          <Hidden mdUp implementation="css">
            <span onClick={handleClickMenu} className={classes.linkText}>
              {rtlActive ? `RT-${translate.profile}` : translate.profile}
            </span>
          </Hidden>
        </Button>
        <Popper
          open={Boolean(openMenu)}
          anchorEl={openMenu}
          transition
          disablePortal
          placement="bottom"
          className={classNames({
            [classes.popperClose]: !openMenu,
            [classes.popperResponsive]: true,
            [classes.popperNav]: true
          })}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              id="profile-menu-list"
              style={{ transformOrigin: "0 0 0" }}
            >
              <Paper className={classes.dropdown}>
                <ClickAwayListener onClickAway={handleCloseMenu}>
                  <MenuList role="menu">
                    {/* <MenuItem
                      onClick={handleCloseMenu}
                      className={dropdownItem}
                    >
                      {rtlActive ? `RT-${translate.profile}` : translate.profile}
                    </MenuItem> */}
                    <MenuItem
                      onClick={goToProfile}
                      className={dropdownItem}
                    >
                      {rtlActive ? `RT-Perfil` : 'Perfil'}
                    </MenuItem>
                    <Divider light />
                    <MenuItem
                      onClick={goToSettings}
                      className={dropdownItem}
                    >
                      {rtlActive ? `RT-Configuraciones` : 'Configuraciones'}
                    </MenuItem>
                    <Divider light />
                    <MenuItem
                      onClick={handleLogout}
                      className={dropdownItem}
                    >
                      {rtlActive ? `RT-${translate.logout}` : translate.logout}
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}

HeaderLinks.propTypes = {
  rtlActive: PropTypes.bool
};
