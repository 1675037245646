import React from "react";
import moment from "moment"
import "moment/locale/es";
import {strings as translate, get_language} from "../../locale";

// "@material-ui/core/styles/makeStyles
import makeStyles from "@material-ui/core/styles/makeStyles";

// core components
// import CustomInput from "components/CustomInput/CustomInput";

// "@material-ui/core
/* import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox"; */

// "@material-ui/icons
// import {Check} from "@material-ui/icons";

import checkboxStyles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch";

const useStyle = makeStyles(() => ({
    cellString: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        paddingLeft: '5px',
        paddingRight: '5px'
    },
    cellLink: {
        textDecoration: 'underline',
        cursor: 'pointer'
    },
    cellImage: {
        maxHeight: '60px',
        width: 'auto'
    },
    cellNumber: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        justifyContent: 'flex-end',
        display: 'inline-flex',
        width: '100%',
        paddingLeft: '10px',
        paddingRight: '10px'
    }
}))
const useCheckboxStyle = makeStyles(checkboxStyles);

const CustomCell = ({
                        value: initialValue,
                        row: { index },
                        column: { id },
                        updateMyData, // This is a custom function that we supplied to our table instance
                        onOpenPopupService,
                        cell,
                        ...rest
                    }) => {
    const { header, type, alt_image } = cell.column;
    // We need to keep and update the state of the cell normally
    const classes = useStyle();
    // const checboxClasses = useCheckboxStyle();
    // const [value, setValue] = React.useState(initialValue);

    // If the initialValue is changed external, sync it up with our state
    /* React.useEffect(() => {
        setValue(initialValue)
    }, [initialValue]) */

    /* const onChange = e => {
        let value = e.target.value;
        if (e.target.type === 'checkbox') {
            value = e.target.checked;
            updateMyData(index, id, value, cell.row.original)
        }
        setValue(value)
    } */

    // We'll only update the external data when the input is blurred
    /* const onBlur = () => {
        if (updateMyData) {
            updateMyData(index, id, value, cell.row.original)
        }
    } */

    const openModal = (item) => {
        // console.log(`/${service}/${queryParams}${id}`);
        if (onOpenPopupService) {
            onOpenPopupService(item)
        }
    }

    /* if ( type === 'checkbox') {
        return <FormControlLabel
            control={
                <Checkbox
                    tabIndex={-1}
                    onClick={onChange}
                    // onBlur={onBlur}
                    checkedIcon={<Check className={checboxClasses.checkedIcon} />}
                    icon={<Check className={checboxClasses.uncheckedIcon} />}
                    classes={{
                        checked: checboxClasses.checked,
                        root: checboxClasses.checkRoot
                    }}
                    checked={value}
                />
            }
            classes={{ label: checboxClasses.label }}
            label={false}
        />
    } */

    /*if (editable === true) {
        return <CustomInput
            inputProps={{
                type: type === 'image' ? 'text' : type,
                value: value || '',
                onChange,
                onBlur
            }}
        />
    }*/

    if ( type === 'image' ) {
        return (
            <a href={initialValue} target="_blank" rel="noopener noreferrer">
                <img 
                    src={initialValue} 
                    className={classes.cellImage} 
                    alt={ alt_image ? alt_image : header }
                />
            </a>
        );
    }

    if ( type === 'date' ) {
        moment.locale(get_language);
        return (
            <span 
                className={classes.cellString}
            >
                {moment.utc(initialValue).format("L")}
            </span>
        );
    }

    if ( type === 'link' ) {
        const {id} = cell.row.original;
        return (
            <span 
                onClick={() => openModal({...cell.column, id, row: cell.row.original})} 
                className={classes.cellString + ' ' + classes.cellLink}
            >
                {initialValue}
            </span>
        );
    }
    if (typeof initialValue === 'object' && id !== 'actions' && initialValue != null) {
        return (
            <span 
                className={classes.cellString}
            >
                {initialValue.name}
            </span>
        );
    }

    if (typeof initialValue === 'boolean') {
        return (
            <span 
                className={classes.cellString}
            >
                {translate[initialValue === true ? 'yes' : 'no']}
            </span>
        );
    }

    if (typeof initialValue === 'number') {
        return (
            <span 
                className={classes.cellNumber}
            >
                {initialValue}
            </span>
        );
    }
    
    return (
        <span 
            className={classes.cellString}
        >
            {initialValue}
        </span>
    );

    /*if (editable === undefined || editable === false) {
        if ( type === 'image' ) {
            return <img src={initialValue} alt={ alt_image ? alt_image : header }/>
        }

        return initialValue;
    }

    return <CustomInput
        inputProps={{
            type: type === 'image' ? 'text' : type,
            value: value || '',
            onChange,
            onBlur
        }}
    />*/
}

export default CustomCell;
