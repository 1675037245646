import { primaryColor, dangerColor } from 'assets/jss/material-dashboard-pro-react.js';

const pricingStyle = {
    root: {
        position: 'relative'
    },
    container: {
        position: 'relative'
    },
    row: {
        position: 'relative'
    },
    rowWithSeparator: {
        position: 'relative',
        "&:after": {
            content: "''",
            position: 'absolute',
            width: 'calc(100% - 45px)',
            margin: '0 0 0 30px',
            display: 'block',
            borderBottom: '1px solid ' + primaryColor[1],
            bottom: 0
        }
    },
    cell: {
        padding: '15px',
        height: '100%',
        fontSize: '12px',
        lineHeight: '14px'
    },
    cellCenter: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        "& h4": {
            fontSize: '16px',
            margin: '10px 0 0 0',
            fontWeight: '700',
            lineHeight: '24px'
        },
        "& h5": {
            fontSize: '34px',
            margin: '0',
            fontWeight: '400',
            lineHeight: '34px'
        },
        "& h6": {
            fontSize: '12px',
            margin: '0',
            fontWeight: '400',
            textTransform: 'none'
        }
    },
    cellCenterHorizontal: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center'
    },
    cellCenterActive: {
        color: 'white',
        "& h4": {
            color: 'white'
        },
        "& h5": {
            color: 'white'
        },
        "& h6": {
            color: 'white'
        }
    },
    cellLeft: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start'
    },
    helpIcon: {
        color: primaryColor[0],
        display: 'inline-block',
        marginLeft: '6px'
    },
    clearIcon: {
        color: dangerColor[0]
    },
    rowBackground: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        top: 0,
    },
    backgroundItem: {
        "&:before": {
            content: '""',
            display: 'block',
            height: '100%',
            width: '100%',
            border: '1px solid ' + primaryColor[0],
            borderRadius: '8px',
            backgroundColor: 'white'
        }
    },
    backgroundItemActive: {
        "&:before": {
            backgroundColor: primaryColor[0]
        }
    },
    switch: {
        marginBottom: '16px'
    },
    buttonActive: {
        backgroundColor: 'white',
        color: primaryColor[0],
        "&:hover, &:active, &:focus, &:focus-visible, &:visited, &:target": {
            backgroundColor: 'white !important',
            color: primaryColor[0] + ' !important'
        }
    },
}

export default pricingStyle;