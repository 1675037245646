import React, { useEffect, useState } from 'react'
import { trackPromise } from 'react-promise-tracker'
import LoadingIndicator from 'components/Loader/LoadingIndicator'

import SubscriptionServices from '../../services/SubscriptionServices'
import CoachServices from 'services/CoachServices'

import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import { 
    Card, 
    FormControl, 
    FormControlLabel, 
    RadioGroup, 
    Radio, 
    makeStyles, 
    Button,
    Fade
} from '@material-ui/core'
import LockIcon from '@material-ui/icons/Lock';
import CardBody from 'components/Card/CardBody'

import checkoutStyle from 'assets/jss/material-dashboard-pro-react/views/checkoutStyle'

import iBankLogo from 'assets/img/logos/iBank-Logo_1@1x.png'
import paypalLogo from 'assets/img/logos/paypal-784404_640@1x.png'
import mercadopagoLogo from 'assets/img/logos/mercadopago-784404_640@1x.png'
import iegLogo from 'assets/img/logos/logo_IEG_Group_ISO_200x226.png'
import { withRouter } from 'react-router';

const useStyles = makeStyles(checkoutStyle)

const Checkout = (props) => {
    const classes = useStyles()
    const [payment, setPayment] = useState('paypal')
    const [plan, setPlan] = useState({})
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        setPlan(JSON.parse(sessionStorage.getItem('selectedPlan')))
        setIsLoading(false)
        console.log(JSON.parse(sessionStorage.getItem('selectedPlan')));
    }, [])

    const handleChange = (event) => {
        setPayment(event.target.value);
    }

    const handlePaymentClick = () => {
        setIsLoading(true)

        trackPromise(paymentService())
            .then(() => {
                setIsLoading(false)
            })
        console.log(payment, plan);
    }

    const paymentService = async () => {
        const res = await SubscriptionServices.validateBillingAgreements(plan.id_origin_plan)

        if( res.data.response && !res.data.response.error ) {
            console.log('crearPago', plan.id_origin_plan, payment);
            trackPromise(createPayment())
                .then(() => {
                    setIsLoading(false)
                })
        }
    }

    const createPayment = async () => {
        console.log(plan.id_origin_plan, payment);
        const res = await SubscriptionServices.createBillingAgreements(plan.id_origin_plan, payment)
        
        CoachServices.setCurrentUser( res.data.response.profile, res.data.response.session );
    }

    const handlePaymentCancel = () => {
        props.history.goBack()
    }
    
    return (
    <>
        <LoadingIndicator
            type="TailSpin"
            color="#2e82ef"
            height={50}
            width={50}
        />
        <Fade in>
            <GridContainer direction="row" justify="center">
                <GridItem xs={12} sm={6}>
                    <Card>
                        <CardBody>
                            <h2 className={classes.title}>Introduce los datos de pago</h2>
                            <h3 className={classes.subtitle}>Compra directa sin prueba gratuita de 30 días</h3>
                            <FormControl component="fieldset" fullWidth className={classes.form}>
                                <RadioGroup name="payment" value={payment} onChange={handleChange}>
                                    <Card className={classes.paymentOption + ' ' + classes.paymentOptionDisabled}>
                                        <CardBody>
                                            <GridContainer direction="row" justify="space-between">
                                                <GridItem>
                                                    <FormControlLabel value="disabled" disabled control={<Radio />} label="ibank" />
                                                </GridItem>
                                                <GridItem className={classes.logoContainer}>
                                                    Próximamente disponible <img src={iBankLogo} className={classes.paymentLogo} />
                                                </GridItem>
                                            </GridContainer>
                                        </CardBody>
                                    </Card>
                                    <Card className={classes.paymentOption}>
                                        <CardBody>
                                            <GridContainer direction="row" justify="space-between">
                                                <GridItem>
                                                    <FormControlLabel value="paypal" control={<Radio color="primary" />} label="paypal" />
                                                </GridItem>
                                                <GridItem className={classes.logoContainer}>
                                                    <img src={paypalLogo} className={classes.paymentLogo} />
                                                </GridItem>
                                            </GridContainer>
                                        </CardBody>
                                    </Card>
                                    <Card className={classes.paymentOption}>
                                        <CardBody>
                                            <GridContainer direction="row" justify="space-between">
                                                <GridItem>
                                                    <FormControlLabel value="mercadopago" control={<Radio color="primary" />} label="mercadopago" />
                                                </GridItem>
                                                <GridItem className={classes.logoContainer}>
                                                    Sólo Argentina <img src={mercadopagoLogo} className={classes.paymentLogo} />
                                                </GridItem>
                                            </GridContainer>
                                        </CardBody>
                                    </Card>
                                </RadioGroup>
                            </FormControl>
                            <GridContainer direction="column" className={classes.footer}>
                                <GridItem className={classes.footerText}>
                                    <p>Al "Suscribirse" está aceptando la contratación de una licencia con modalidad de pago recurrente, lo que significa que los cobros se realizan periódicamente (mensual, anual) de manera automática acorde al plan contratado.</p>
                                    <p>Para anular el plan actual, debe cancelar la suscripción. Una vez que anule la suscripción, solo podrá ingresar a la herramienta hasta el vencimiento de la misma.</p>
                                </GridItem>
                                <GridItem
                                    container
                                    direction="column"
                                    alignItems="center"
                                    className={classes.footerButtons}
                                >
                                    <Button
                                        color="primary"
                                        size="small"
                                        variant="contained"
                                        onClick={handlePaymentClick}
                                        className={classes.buttonPayment}
                                    >
                                        {plan.subscription_category === 'Monthly' ? 'PAGO MENSUAL:' : 'PAGO ANUAL:'} <strong>${plan.price} USD</strong>
                                    </Button>
        
                                    <Button
                                        color="primary"
                                        size="small"
                                        className={classes.buttonCancel}
                                        onClick={handlePaymentCancel}
                                    >
                                        Cancelar
                                    </Button>
                                </GridItem>
                                <GridItem className={classes.footerText}>
                                    <p className={classes.safeText}><LockIcon fontSize="small" className={classes.lockIcon} /> Pago seguro SSL de 256 bits</p>
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={4}>
                    <Card>
                        <CardBody>
                            <h2 className={classes.title}>Items comprados</h2>
                            <h3 className={classes.subtitle}>Has seleccionado los elementos a continuación</h3>
                            <GridContainer direction="column" className={classes.itemContainer}>
                                <GridItem>
                                    <GridContainer direction="row">
                                        <GridItem xs={2}>
                                            <img src={iegLogo} className={classes.iegLogo} />
                                        </GridItem>
                                        <GridItem xs={5}>
                                            <h4 className={classes.itemName}>{plan.name}</h4>
                                            <p className={classes.itemDescription}>{plan.description}</p>
                                        </GridItem>
                                        <GridItem xs={5} className={classes.itemPrice}>
                                            {plan.price} USD
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>
                                <GridItem className={classes.itemFooter}>
                                    <GridContainer>
                                        <GridItem xs={7} className={classes.itemTotalTitle}>Total</GridItem>
                                        <GridItem xs={5} className={classes.itemTotalPrice}>{plan.price} USD</GridItem>
                                    </GridContainer>
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </Fade>
    </>
    )
}

export default withRouter(Checkout)
