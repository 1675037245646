import React from "react";

// @material-ui/icons
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import * as Icons from '@material-ui/icons'

// core components
import Button from "components/CustomButtons/Button.js";

const ActionButton = (props) => {
    const { type, data, onClick, title, buttonicon, uniqueId } = props;
    const [color, setColor] = React.useState('primary');
    const [className, setClassName] = React.useState('default');
    const [icon, setIcon] = React.useState('');

    React.useEffect(() => {
        switch ( type ) {
            case 'edit':
                setColor("warning");
                setClassName("edit");
                setIcon(<Edit />);
                break;
            case 'delete':
                setColor("danger");
                setClassName("remove");
                setIcon(<Close />);
                break;
            default:
                let DynamicIcon = Icons[buttonicon];
                setColor("primary");
                setClassName(type);
                if(DynamicIcon) {
                    setIcon(<DynamicIcon />);
                }
                break;
        }
        // console.log(uniqueId)
    }, [type, buttonicon, uniqueId]);

    const handleOnClick = (e) => {
        if (onClick) {
            if( uniqueId ) {
                onClick(data[uniqueId.accessor]);
            } else {
                onClick(data.id);
            }
        }
    }

    return (<Button
        justIcon
        round
        simple
        color={color}
        className={className}
        onClick={handleOnClick}
        title={title}
    >
        {icon}
    </Button>);
};

export default ActionButton;
