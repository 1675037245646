import React, { useEffect } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";

import AuthLayout from "layouts/Auth.js";
import RtlLayout from "layouts/RTL.js";
import AdminLayout from "layouts/Admin.js";
import IframeLayout from "layouts/Iframe.js";

import UserProvider from "providers/UserProvider";

import { useDispatch, useSelector } from "react-redux";

import { loadMasters } from "./redux/masters";
import { loadToolTips } from "./redux/tooltips";

import { useParams, useLocation } from "react-router-dom";

import Iframe from "./views/GenericPage/Iframe";

const hist = createBrowserHistory();
const EtAngularLayout = () => {
  const { pathname } = useLocation();
  return (
    <div>
      <Switch>
        <Route
          path="/"
          render={(props) => {
            return (
              <Iframe {...props} config={{ layout: "/src/et/", pathname }} />
            );
          }}
        />
      </Switch>
    </div>
  );
};

const App = () => {
  const dispatch = useDispatch();
  const masters = useSelector((store) => store.masters.object);
  const tooltips = useSelector((store) => store.tooltips.object);

  useEffect(() => {
    dispatch(
      loadMasters([
        "sports",
        "criterias_pref_units",
        "question_choice_templates",
      ])
    );
    dispatch(loadToolTips());
  }, []);

  return (
    <UserProvider>
      <Router history={hist}>
        <Switch>
          <Route path="/src/et/" component={EtAngularLayout} />
          <Route path="/rtl" component={RtlLayout} />
          <Route path="/auth" component={AuthLayout} />
          <Route path="/admin" component={AdminLayout} />
          <Route path="/iframe" component={IframeLayout} />
          <Route path="/et" component={AdminLayout} />
          <Route path="/et-academy" component={AdminLayout} />
          <Redirect from="/" to="/auth/login" />
        </Switch>
      </Router>
    </UserProvider>
  );
};

export default App;
