/*eslint-disable*/
import React from "react";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import {
    useTable,
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    useGlobalFilter,
} from "react-table";
import classnames from "classnames";

// A great library for fuzzy filtering/sorting items
import matchSorter from "match-sorter";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import {
    GlobalFilter,
    DefaultColumnFilter,
    SelectColumnFilter,
    SliderColumnFilter,
    NumberRangeColumnFilter
} from "./Filters";
import Pagination from "./Pagination";
import ActionButton from "./ActionButton";
import CustomCell from "./CustomCell";
import FormDialog from "../CustomDialog/FormDialog";
import Button from "components/CustomButtons/Button";

import styles from "./styles";
import SweetAlert from "react-bootstrap-sweetalert/dist";
import {strings as translate} from "../../locale";

const useStyles = makeStyles(styles);

function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [row => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val;

const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, onCheckboxClick, ...rest }, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        const handleOnClick = ( e ) => {
            if (onCheckboxClick) {
                onCheckboxClick(rest);
            }
        }

        return (
            <>
                <input type="checkbox" ref={resolvedRef} onClick={handleOnClick} {...rest} />
            </>
        )
    }
)

const setHooks = (hooks, selectable, handleOnChange = null)  => {
    if (selectable === true) {
        hooks.visibleColumns.push(columns => [
            // Let's make a column for selection
            {
                id: 'selection',
                // The header can use the table's getToggleAllRowsSelectedProps method
                // to render a checkbox
                Header: ({ getToggleAllPageRowsSelectedProps }) => (
                    <div>
                        <IndeterminateCheckbox onCheckboxClick={handleOnChange} {...getToggleAllPageRowsSelectedProps()} />
                    </div>
                ),
                // The cell can use the individual row's getToggleRowSelectedProps method
                // to the render a checkbox
                Cell: ({ row }) => (
                    <div>
                        <IndeterminateCheckbox onCheckboxClick={handleOnChange} {...row.getToggleRowSelectedProps()} />
                    </div>
                )
            },
            ...columns,
        ])
    }
}

function CustomTable ( props ) {
    const {globalFiltersForm} = props;
    const classes = useStyles();
    const [columns, setColumns] = React.useState([]);
    const [rows, setRows] = React.useState([]);
    const [confirmAlert, setConfirmAlert] = React.useState(false);
    const [deleteId, setDeleteId] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [formData, setFormData] = React.useState({});

    const [uniqueId, setUniqueId] = React.useState({});

    const handleOnConfirm = (id) => {
        hideConfirmAlert();
        if (props.onRowDeleted) {
            props.onRowDeleted(id).then(r => {
                const res = rows.filter(item=>item.id !== id);
                setRows(res);
            });
        }
    }

    const hideConfirmAlert = () => {
        setConfirmAlert(false);
        setDeleteId(null);
    }

    const openConfirmAlert = id => {
        setConfirmAlert(true);
        setDeleteId(id);
    }

    const handleOnEdit = id => {
        const path = props.match.path;
        props.history.push(`${path}/edit/${id}`);
    }

    const getColumns = () => {
        if (!props.data.metadata) {
            setColumns([]);
            return;
        }
        const cols = props.data.metadata.map(col => {
            switch (col.Filter) {
                case 'SelectColumnFilter':
                    col.Filter = SelectColumnFilter;
                break;
                case 'SliderColumnFilter':
                    col.Filter = SliderColumnFilter;
                break;
                case 'NumberRangeColumnFilter':
                    col.Filter = NumberRangeColumnFilter;
                break;
            }

            return {
                ...col
            }
        });
        if (props.actionButtons !== false) {
            cols.push({header: translate.actions, accessor: 'actions'});
        }
        setColumns(cols)
    }

    const openFormDialog = (row, global_action, uniqueId) => {
        global_action.queryParams = props.data.queryParams
        if(_.filter(global_action.form, _.matches({"accessor":uniqueId.accessor})).length === 0) {
            global_action.form.push(uniqueId);
        }
        const form_data = {row, global_action, uniqueId};
        setOpen(true)
        setFormData(form_data)
    }

    const getActions = (row) => {
        const uniqueId = props.data.metadata.filter(item => item.unique).pop()
        setUniqueId(uniqueId)
        
        const onHandleClick = (e, type, global_action) => {
            switch(type) {
                case 'edit':
                    handleOnEdit(e);
                    break;
                case 'delete':
                    openConfirmAlert(e);
                    break;
                case 'link':
                    window.location = global_action.calltoaction;
                    break;
                default:
                    openFormDialog(row, global_action, uniqueId)
                    break;
            }
        }

        return (
            <>
                {
                row.actions ? 
                row.actions.map((item, key) => {
                    const global_action = _.find(props.data.actions, function(o) { return o.code === item; });
                    if(global_action && global_action.type !== "row") return null;
                    return <ActionButton 
                        key={key}
                        type={item}
                        data={row}
                        uniqueId={uniqueId}
                        buttonicon={global_action && global_action.iconbutton ? global_action.iconbutton : 'HelpOutline'}
                        onClick={e => onHandleClick(e, item, global_action)}
                        title={global_action && global_action.titlebutton ? global_action.titlebutton : translate[item]}
                        buttonicon={global_action && global_action.iconbutton ? global_action.iconbutton : null}
                    />
                })
                :
                <>
                    <ActionButton
                        type="edit"
                        data={row}
                        uniqueId={uniqueId}
                        onClick={handleOnEdit}
                        title={translate.edit}
                    />
                    <ActionButton
                        type="delete"
                        data={row}
                        uniqueId={uniqueId}
                        onClick={openConfirmAlert}
                        title={translate.delete}
                    />
                </>
                }
            </>
        );
    }

    const getData = () => {
        if (!props.data.data) {
            setRows([]);
            return;
        }
        const newData = [...props.data.data];
        const rs = newData.map( (row, index) => {
            return {
                ...row,
                actions: getActions(row)
            };
        });
        setRows(rs)
    }

    React.useEffect(() => {
        getColumns()
        getData()
    }, [props.data])

    const handleClose = () => { setOpen(false); };

    const handleSent = (row) => {
        if(props.updateTableRow) {
            props.updateTableRow(row)
        }
        handleClose();
    };

    const handleSentError = (e) => { handleClose(); };

    return (<>
        {
            props.data.actions && 
            props.data.actions.map((item, key) => {
                if(item.type !== 'form') {
                    return null;
                }

                if(globalFiltersForm) {
                    item.filter = globalFiltersForm;
                }

                return (
                    <Button
                        key={key}
                        className="btn btn-primary"
                        onClick={() => openFormDialog(null, item, uniqueId)}
                    >
                    {item.titlebutton}
                    </Button>
                )
            })
        }
        {
            (columns.length > 0 && rows.length > 0) ? 
            <LoadTable 
                {...props}
                data={{columns, data: rows}}
            />
             :
            <div className={classes.tableEmtpyCase}>{translate.empty_table}</div>
        }
        { 
            confirmAlert && 
            <SweetAlert
                danger
                onConfirm={() => handleOnConfirm(deleteId)}
                confirmBtnCssClass={classes.button + " " + classes.danger}
                showCancel
                onCancel={hideConfirmAlert}
                cancelBtnCssClass={classes.button + " " + classes.success}
                customClass={classes.sweetAlertCustomClass}
                showCloseButton
                title={translate.confirm_delete_title}>
                {translate.formatString(translate.confirm_delete_text, deleteId)}
            </SweetAlert>
        }
        <FormDialog open={open} onClose={handleClose} onSent={handleSent} onSentError={handleSentError} data={formData} />
    </>)
}

// Our table component
function LoadTable({
    selectable,
    defaultFilters,
    updateMyData,
    onOpenPopupService,
    skipPageReset,
    paginationBottom,
    paginationTop,
    ...props
}) {
    const columns = React.useMemo(()=>props.data.columns);
    const data = React.useMemo(()=>props.data.data);

    const countActions = props.data.data[0].actions ? props.data.data[0].actions.props.children.length : 0;

    const [numberOfRows, setNumberOfRows] = React.useState(10);
    const [pageSelect, handlePageSelect] = React.useState('');
    const classes = useStyles();
    const filterTypes = React.useMemo(
        () => ({
            // Add a new fuzzyTextFilterFn filter type.
            fuzzyText: fuzzyTextFilterFn,
            // Or, override the default text filter to use
            // "startWith"
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                    const rowValue = row.values[id];
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true;
                });
            }
        }),
        []
    );

    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: defaultFilters ? DefaultColumnFilter : false,
            Cell: CustomCell
        }),
        []
    );

    const inputSelectedRowIds = React.useRef(null);
    const inputSelectedFlatRows = React.useRef(null);

    const handleOnSelectedRowChange = (e) => {
        if (props.onSelectedRowChange) {
            setTimeout (() => {
                props.onSelectedRowChange({
                    ...e,
                    selectedRowIds: JSON.parse(inputSelectedRowIds.current.value),
                    selectedFlatRows: JSON.parse(inputSelectedFlatRows.current.value)
                });
            }, 100);
        }
    }

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        selectedFlatRows,
        state: {
            pageIndex,
            pageSize,
            selectedRowIds,
            globalFilter
        },
        visibleColumns,
        setHiddenColumns,
        preGlobalFilteredRows,
        setGlobalFilter,
    } = useTable(
        {
            columns,
            data,
            defaultColumn, // Be sure to pass the defaultColumn option
            autoResetPage: !skipPageReset,
            updateMyData,
            onOpenPopupService,
            filterTypes,
            initialState: {
                pageSize: 10,
                pageIndex: 0
            },
        },
        useFilters, // useFilters!
        useGlobalFilter, // useGlobalFilter!
        useSortBy,
        usePagination,
        useRowSelect,
        hooks => {
            setHooks(hooks, selectable, handleOnSelectedRowChange);
        }
    );

    // We don't want to render all of the rows for this example, so cap
    // it for this use case
    // const firstPageRows = rows.slice(0, 10);
    let pageSelectData = Array.apply(null, Array(pageOptions.length)).map(
        function() {}
    );
    let numberOfRowsData = [5, 10, 20, 25, 50, 100];

    React.useEffect(() => {
        const hiddenColumns = columns.filter(col=>(col.foreign_key || col.isVisible === false)).map(col=>col.accessor);
        setHiddenColumns(hiddenColumns);
    }, [columns]);

    return (
        <>
            <div className="ReactTable -striped -highlight">
                <div className={classes.headerFilters}>
                    <div className={classes.customInputFilter}>
                        <GlobalFilter
                            preGlobalFilteredRows={preGlobalFilteredRows}
                            globalFilter={globalFilter}
                            setGlobalFilter={setGlobalFilter}
                        />
                    </div>
                </div>
                {
                paginationTop && 
                <div className={"pagination-top " + classes.paginationTop}>
                    <Pagination {...{previousPage,
                                canPreviousPage,
                                pageSelect,
                                gotoPage,
                                handlePageSelect,
                                pageSelectData,
                                numberOfRows,
                                pageIndex,
                                setPageSize,
                                setNumberOfRows,
                                numberOfRowsData,
                                nextPage,
                                canNextPage}}/>
                </div>
                }
                <table {...getTableProps()} className="rt-table">
                    <thead className={"rt-thead -header " + classes.tableTHead}>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()} className="rt-tr">
                            {headerGroup.headers.map((column, key) => (
                                <th className={classnames("rt-th")} key={key} style={{minWidth: column.getHeaderProps().key === 'header_actions' ? `${(47*countActions)}px` : '0'}}>
                                    
                                    <div
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                        className={classnames("rt-th", {
                                            "-cursor-pointer": headerGroup.headers.length - 1 !== key,
                                            "-sort-asc": column.isSorted && !column.isSortedDesc,
                                            "-sort-desc": column.isSorted && column.isSortedDesc,
                                            "-right": column.type === 'number'
                                        })}
                                    >
                                        <div className="rt-resizable-header-content"
                                             style={{
                                                 paddingRight: "7px"
                                             }}>
                                            {column.render("header")}
                                        </div>
                                    </div>
                                    {/* Render the columns filter UI */}
                                    <div>
                                        {headerGroup.headers.length - 1 === key
                                            ? null
                                            : column.canFilter
                                                ? column.render("Filter")
                                                : null}
                                    </div>
                                </th>
                            ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody {...getTableBodyProps()} className={"rt-tbody " + classes.tableTBody}>
                    {page.map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr
                                {...row.getRowProps()}
                                className={classnames(
                                    "rt-tr",
                                    { " -odd": i % 2 === 0 },
                                    { " -even": i % 2 === 1 }
                                )}
                            >
                                {row.cells.map(cell => {
                                    return (
                                        <td {...cell.getCellProps()} className="rt-td"  style={{minWidth: cell.getCellProps().key.lastIndexOf('actions') !== -1 ? `${(47*countActions)}px` : '0'}}>
                                            {cell.render("Cell")}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                    </tbody>
                </table>

                <input type="hidden" ref={inputSelectedRowIds} value={JSON.stringify(selectedRowIds)} onChange={()=>{}} />
                <input type="hidden" ref={inputSelectedFlatRows} value={JSON.stringify(selectedFlatRows.map(d=>{d.original.actions = null; return d.original;}))} onChange={()=>{}} />

                {
                paginationBottom && 
                <div className={"pagination-bottom " + classes.paginationBottom}>
                    <Pagination {...{previousPage,
                                canPreviousPage,
                                pageSelect,
                                gotoPage,
                                handlePageSelect,
                                pageSelectData,
                                numberOfRows,
                                pageIndex,
                                setPageSize,
                                setNumberOfRows,
                                numberOfRowsData,
                                nextPage,
                                canNextPage}}/>
                </div>
                }
            </div>
        </>
    );
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
    return rows.filter(row => {
        const rowValue = row.values[id];
        return rowValue >= filterValue;
    });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = val => typeof val !== "number";

export default withRouter(CustomTable);
