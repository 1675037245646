import React, { useState, useReducer, useEffect } from "react";
import {strings as translate} from 'locale';
import {withRouter} from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from '@material-ui/core/IconButton';

// @material-ui/icons
import Email from "@material-ui/icons/Email"
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FormHelperText from "@material-ui/core/FormHelperText";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";

// services
import AuthServices from "services/AuthServices";
import CoachServices from "services/CoachServices";
import { Auth } from 'aws-amplify';

import {UserContext} from "providers/UserProvider";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

import routes from "../../routes";

import bgRegister from  "assets/img/bg-login-right.jpg"

const useStyles = makeStyles(styles);

const LoginForm = (props) => {
  const { onForgotPassword } = props;
    const [cardAnimaton, setCardAnimation] = useState("cardHidden");
    const [inputValues, setInputValues] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {email: '', password: ''}
    );
    const [apiError, setApiError] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const useUserContext = React.useContext(UserContext);

    const classes = useStyles();

    useEffect(() => {
      let id = setTimeout(function() {
        setCardAnimation("");
      }, 700);
      // Specify how to clean up after this effect:
      return function cleanup() {
        window.clearTimeout(id);
      };
    });
  
    const setInput = (e) => {
      const { name, value } = e.target;
      setInputValues({ [name]: value });
    }

    const handleClickShowPassword = () => {
      setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };
    const handlerForgotPassword = () => {
      if(onForgotPassword) {
        onForgotPassword();
      }
    }

    const login = async (e) => {
      e.preventDefault()
      let fullpath = 'admin/dashboard';
      // obtenemos la primer ruta configurada en routes.js
      const {layout, path} = routes[0];
      if (path !== '/login') {
        fullpath = `${layout}${path}`;
      }
      
      if (!inputValues.email.trim() && !inputValues.password.trim()) {
        setApiError( translate.incorrect_username_or_password );
        return;
      }
      setApiError( '' );
      
      try {
          const user = await AuthServices.login(inputValues.email, inputValues.password);
          if( user.data && !user.data.error) {
            const coach = await CoachServices.updatePreferences(user.data.response.session);
            if(coach) {
              user.data.response.preferences = coach
            }
            AuthServices.saveUser(user.data.response);
            useUserContext.updateUser();
            // props.history.push(fullpath);
          } else {
            setApiError( 'Error de inicio de sesión' );
          }
      } catch (error) {
          console.log('error signing in', error);
          setApiError(error.message);
      }
    }

    const handleRegisterClick = () => {
      props.history.push('/auth/signup')
    }

    return (
      <div className={classes[cardAnimaton] + ' ' + classes.loginBody}>
        <div className={classes.loginForm}>
          <form onSubmit={login}>
            <Card className={classes.cardContainer}>
              <CardBody className={classes.cardBody}>
                <CustomInput
                  labelText={translate.email + ' *'}
                  id="email"
                  formControlProps={{
                  fullWidth: true
                  }}
                  value={inputValues.email}
                  inputProps={{
                  onChange: setInput,
                  name: "email",
                  endAdornment: (
                    <InputAdornment position="end">
                      <Email className={classes.inputAdornmentIcon} />
                    </InputAdornment>
                  )
                  }}
                />
                <CustomInput
                  labelText={translate.password + ' *'}
                  id="password"
                  formControlProps={{
                  fullWidth: true
                  }}
                  value={inputValues.password}
                  inputProps={{
                  onChange: setInput,
                  name: "password",
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                      >
                          {showPassword ? <Visibility className={classes.inputAdornmentIconButton} /> : <VisibilityOff className={classes.inputAdornmentIconButton} />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  type: showPassword ? 'text' : 'password',
                  autoComplete: "off"
                  }}
                />
                <div className={classes.forgotPasswordContainer}>
                  <button 
                    type="button"
                    className={classes.btnForgotPassword}
                    onClick={handlerForgotPassword}>
                      {translate.forgot_your_password}
                  </button>
                </div>
                {apiError && <FormHelperText error>{apiError}</FormHelperText>}
              </CardBody>
              <CardFooter className={classes.cardFooter + ' ' + classes.justifyContentCenter}>
                <Button color="primary" size="lg" block type="submit">
                    {translate.login_button_text}
                </Button>
              </CardFooter>
            </Card>
          </form>
        </div>
        <div className={classes.loginAccount} style={{backgroundImage: "url(" + bgRegister + ")"}}>
          <div className={classes.accountHeader}>
            <h2>¡SÚMATE Y DISFRUTA DE LOS BENEFICIOS DE ENDURANCE TOOL!</h2>
            <p>Prueba de 30 días en cualquier plan</p>
          </div>
          <div className={classes.accountButton}>
                <Button color="primary" size="lg" block type="button" onClick={handleRegisterClick}>
                    {translate.register}
                </Button>
          </div>
        </div>
      </div>
    )
}

export default withRouter(LoginForm)
