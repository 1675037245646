import React from 'react'

export const GenericPageContext = React.createContext({
    config: {}
});

const GenericPageProvider = (props) => {
    return (
        <GenericPageContext.Provider value={props.config}>
            { props.children }
        </GenericPageContext.Provider>
    )
}

export default GenericPageProvider
