import API from './api.js';
import axios from 'axios';
import _ from 'lodash';
let cancelToken;

const MainServices = {
    getMetadata: (name) => {
        cancelToken = axios.CancelToken.source();

        return API({
            method: 'GET',
            url: `/metadata/`,
            params: {
                model: name
            },
            cancelToken: cancelToken.token
        });
    },
    getItem: (name, id) => {
        cancelToken = axios.CancelToken.source();

        return API({
            method: 'GET',
            url: `/${name}/`,
            params: {
                id: id
            },
            cancelToken: cancelToken.token
        });
    },
    list: (name, args = null) => {
        if(!name) return null;
        cancelToken = axios.CancelToken.source();

        return API({
            method: 'GET',
            url: `/${name}/`,
            cancelToken: cancelToken.token,
            params: args
        })
    },
    filteredList: (name, queryString = '') => {
        if(!name) return null;
        cancelToken = axios.CancelToken.source();

        return API({
            method: 'GET',
            url: `/${name}/${queryString}`,
            cancelToken: cancelToken.token
        })
    },
    add: (name, data) => {
        const formData = new FormData();
        cancelToken = axios.CancelToken.source();

        Object.keys(data).forEach( item => {
            formData.append(item, data[item]);
        });

        return API({
            method: 'POST',
            url: `/${name}/`,
            data: data,
            cancelToken: cancelToken.token
        });
    },
    edit: (name, data) => {
        cancelToken = axios.CancelToken.source();

        return API({
            method: 'PUT',
            url: `/${name}/`,
            params: {
                id: data.id
            },
            data: data,
            cancelToken: cancelToken.token
        });
    },
    delete: (name, id) => {
        const formData = new FormData();
        cancelToken = axios.CancelToken.source();

        formData.append('id', id);

        return API({
            method: 'DELETE',
            url: `${name}/`,
            params: {
                id: id
            },
            data: formData,
            cancelToken: cancelToken.token
        });
    },
    update: (name, data) => {
        const formData = new FormData();
        cancelToken = axios.CancelToken.source();

        Object.keys(data).forEach( item => {
            formData.append(item, data[item]);
        });

        return API({
            method: 'PATCH',
            url: `${name}/`,
            params: {
                id: data.id
            },
            data: formData,
            cancelToken: cancelToken.token
        });
    },
    addPopup: (name, data, global_action) => {
        cancelToken = axios.CancelToken.source();
        delete data.metadata;
        delete data.global_action;
        
        const body = Object.assign({}, global_action.body);
        let newData = Object.assign(body, {row: {}, form: data, filter: {}});
        if(global_action.filter) {
            newData.filter = global_action.filter;
        }
        // cada valor string del body que empiece con # seguido del nombre de campo
        // tiene que ser reemplazado por una variable del mismo nombre del queryParams
        _.forIn(newData, (v, k) => {
            if(typeof v === 'string') {
                if(v.match(/#(.+)/)) {
                    // obtenemos el nombre del campo
                    const field_name = v.match(/#(.+)/)[1];
                    // reemplazamos el valor por el del queryParams
                    newData[k] = global_action.queryParams[field_name];
                }
            }
        })

        return API({
            method: 'POST',
            url: `/${name}/`,
            data: JSON.stringify(newData),
            cancelToken: cancelToken.token
        });
    },
    editPopup: (name, data, global_action) => {
        cancelToken = axios.CancelToken.source();
        delete data.metadata;
        
        const body = Object.assign({}, global_action.body);
        const newData = Object.assign(body, {row: data, form: {}});
        
        return API({
            method: 'POST',
            url: `/${name}/`,
            data: JSON.stringify(newData),
            cancelToken: cancelToken.token
        });
    },
    cancel: () => {
        if (typeof cancelToken != typeof undefined) {
            cancelToken.cancel("Request canceled.");
        }
    }
};
export default MainServices;
