import React from "react";
import PropTypes from "prop-types";

import {usePromiseTracker} from "react-promise-tracker";
import Loader from 'react-loader-spinner';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-dashboard-pro-react/components/loadingStyle";

const useStyles = makeStyles(styles);

export default function LoadingIndicator(props){
    const {promiseInProgress} = usePromiseTracker({area: props.area});
    const classes = useStyles();
    const {type, color, height, width, loadingText} = props;
    if (props.area !== null && props.area !== '') {
        return (
            promiseInProgress && <div
                className={props.fitContainer ? classes.fullContainerLoader : classes.rowCenteredLoader}
            >
                <Loader type={type} color={color} height={height} width={width} />
            </div>
        );
    }
    return (
        promiseInProgress &&
        <div className={classes.fullPageLoader}>
            <Loader type={type} color={color} height={height} width={width}/>
            <div className={classes.fullPageLoaderText}>{loadingText}</div>
        </div>
    );
};

LoadingIndicator.defaultProps = {
    area: "",
    color: "#2BAD60",
    height: 30,
    width: 30,
    loadingText: "Loading...",
    type: "ThreeDots"
};

LoadingIndicator.propTypes = {
    area: PropTypes.string,
    color: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number,
    loadingText: PropTypes.string,
    type: PropTypes.oneOf([
        "Audio",
        "Ball-Triangle",
        "Bars",
        "Circles",
        "Grid",
        "Hearts",
        "Oval",
        "Puff",
        "Rings",
        "TailSpin",
        "ThreeDots",
    ])
};
