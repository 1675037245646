import {
  blackColor,
  hexToRgb
} from "assets/jss/material-dashboard-pro-react.js";

const loadingStyle = {
  loadingArea: {
    width: "100%",
    height: "auto",
    display: "flex",
    flexDirection: 'column',
    justifyContent: "center",
    alignItems: "center"
  },
  fullPageLoader: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "fixed",
    top: "0",
    left: "0",
    backgroundColor: "rgba(" + hexToRgb(blackColor) + ", .7)",
    zIndex: "9999"
  },
  fullContainerLoader: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: "0",
    left: "0",
    bottom: "0",
    right: "0",
    backgroundColor: "rgba(" + hexToRgb(blackColor) + ", .7)",
    zIndex: "9999"
  },
  fullPageLoaderText: {

  },
  rowCenteredLoader: {
    width: "100%",
    height: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
};

export default loadingStyle;
