import React from 'react'
import AuthApi from './AuthApi.js';
import AuthServices from './AuthServices';

const SubscriptionServices = {
    getSubscriptionPlans: () => {
        const data = {
            id: null,
            name: "get_subscription_plans",
            session: null,
            args: {}
        }

        return AuthApi({
            method: "post",
            url: "/front_ws/call/",
            data: data,
            headers: {
                "Accept": "application/json",
                "Authorization": AuthServices.getCurrentToken()
            }
        });
    },
    validateBillingAgreements: (id_origin_plan) => {
        const data = {
            id: null,
            name: "validate_billing_agreements",
            session: null,
            args: {
                id_origin_plan
            }
        }

        return AuthApi({
            method: "post",
            url: "/front_ws/call/",
            data: data,
            headers: {
                "Accept": "application/json",
                "Authorization": AuthServices.getCurrentToken()
            }
        });
    },
    createBillingAgreements: (id_origin_plan, payment_method) => {
        const data = {
            id: null,
            name: "create_billing_agreements",
            session: null,
            args: {
                id_origin_plan,
                payment_method
            }
        }

        return AuthApi({
            method: "post",
            url: "/front_ws/call/",
            data: data,
            headers: {
                "Accept": "application/json",
                "Authorization": AuthServices.getCurrentToken()
            }
        });
    }
}

export default SubscriptionServices
